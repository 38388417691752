import { b2x } from '@b2x/react/src';
import React from 'react';

import { HomePageContentType } from '../contentTypes';
import { Section } from '../Section';
import { BannerSlider } from '../slider/BannerSlider';
import { ProductCategorySlider } from '../slider/ProductCategorySlider';
import { SliderContentA } from '../slider/SliderContentA';
import { SliderContentB } from '../slider/SliderContentB';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';
import { PopUpNewsletterModal } from './PopupNewsletterModal';
export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const page = b2x.usePage<HomePageContentType>();

  const { showModal } = b2x.useModals();
  const { pageReady } = b2x.useAppContext();

  React.useEffect(() => {
    const modalAlreadyShown = b2x.storage.getBoolean('homePageNewsletterModal');
    if (!modalAlreadyShown && pageReady) {
      b2x.wait(3000).then(() => {
        showModal({
          children: <PopUpNewsletterModal />,
          onClose: () => {
            b2x.storage.setBoolean('homePageNewsletterModal', true);
          },
          size: 'default',
          title: '',
        });
      });
    }
  }, [pageReady, showModal]);

  const productsTabs = b2x.useDirectory('HOME_PRODUCTS', {
    populate: { children: { products: b2x.appConfig.api?.productTilePopulate } },
  });

  return (
    <Page noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <>
        <BannerSlider {...page?.content?.body.sliderMainSection} />
        <ProductCategorySlider {...page?.content?.body.productCategorySection} />
        {productsTabs && (
          <Section
            content={page?.content?.body.productsTabContentSection?.content}
            noPaddingX
            title={page?.content?.body.productsTabContentSection?.title}
          >
            <b2x.Tabs
              className="px-lg-3 ps-3"
              contentClassName="mt-4"
              horizontalAlignment={{ md: 'center' }}
              tabStyle="pills"
              tabs={productsTabs.children.map((item) => ({
                children: (
                  <b2x.Listing
                    name={`Home page - ${page?.content?.body.productsTabContentSection?.title}`}
                    products={item.products}
                  >
                    <SliderProducts products={item.products} />
                  </b2x.Listing>
                ),
                title: item.name ?? '',
              }))}
            />
          </Section>
        )}

        <SliderContentA {...page?.content?.body.ourServicesSection} />
        <SliderContentB {...page?.content?.body.blogContentSection} />
      </>
    </Page>
  );
};
