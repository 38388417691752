import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { SliderContentASection } from '../contentTypes';
import { Section } from '../Section';
import { Slider } from './Slider';

export interface SliderContentAProps extends SliderContentASection {}

export const SliderContentA = ({ itemList, title }: SliderContentAProps) => {
  return (
    <Section className="slider-content-a" title={title}>
      {itemList && (
        <div className="d-md-none">
          <div className="row">
            <div className="col-12 pe-0 pe-xl-3">
              <b2x.EqualHeight>
                <Slider
                  loop
                  responsive={{
                    md: {
                      slidesPerView: 2,
                    },
                  }}
                  slidesPerView={1.2}
                  spaceBetween={20}
                >
                  {itemList.map((item, index) => (
                    <SwiperSlide key={item.contentSectionId}>
                      <div className="slide">
                        {item.cta ? (
                          <b2x.DeprecatedCta cta={item.cta}>
                            <b2x.ImageFromContentV1 {...item.img} className="mb-3" fluid />
                          </b2x.DeprecatedCta>
                        ) : (
                          <b2x.ImageFromContentV1 {...item.img} className="mb-3" fluid />
                        )}
                        <div className="swiper-no-swiping">
                          {item.title && (
                            <h3 className="h4 fw-bold title mb-2 text-blue">{b2x.formatHtml(item.title)}</h3>
                          )}

                          {item.content && (
                            <b2x.EqualHeightElement name="content">
                              <div>{b2x.formatHtml(item.content)}</div>
                            </b2x.EqualHeightElement>
                          )}
                        </div>
                        {item.cta && item.cta.label && (
                          <div className="py-2">
                            <b2x.DeprecatedCta className={classnames('text-decoration-none small')} cta={item.cta}>
                              {b2x.formatHtml(item.cta.label)}
                              <b2x.Icon className="ps-1" name="small-arrow-right" size={16} />
                            </b2x.DeprecatedCta>
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}
                </Slider>
              </b2x.EqualHeight>
            </div>
          </div>
        </div>
      )}
      {itemList && (
        <div className="d-none d-md-block">
          <div className="row">
            <Box itemList={itemList} />
          </div>
        </div>
      )}
    </Section>
  );
};

interface BoxContentProps {
  itemList?: b2x.contentTypes.ContentSectionList<{
    content?: string;
    cta?: b2x.contentTypes.CtaContentSection;
    img?: b2x.contentTypes.ImageContentSectionV1;
    title?: string;
  }>;
  sliderOn?: boolean;
}

const Box = ({ itemList, sliderOn = false }: BoxContentProps) => {
  return (
    <b2x.EqualHeight>
      <>
        {itemList &&
          itemList.map((item, index) => (
            <div className="col-md-6" key={item.contentSectionId}>
              {item.cta ? (
                <b2x.DeprecatedCta cta={item.cta}>
                  <b2x.ImageFromContentV1 {...item.img} className="mb-3" fluid />
                </b2x.DeprecatedCta>
              ) : (
                <b2x.ImageFromContentV1 {...item.img} className="mb-3" fluid />
              )}
              {item.title && <h3 className="h4 fw-bold title mb-2 text-blue">{b2x.formatHtml(item.title)}</h3>}

              {item.content && (
                <b2x.EqualHeightElement name="content">
                  <div>{b2x.formatHtml(item.content)}</div>
                </b2x.EqualHeightElement>
              )}

              {item.cta && item.cta.label && (
                <div className="py-2">
                  <b2x.DeprecatedCta className={classnames('text-decoration-none small')} cta={item.cta}>
                    {b2x.formatHtml(item.cta.label)}
                    <b2x.Icon className="ps-1" name="small-arrow-right" size={16} />
                  </b2x.DeprecatedCta>
                </div>
              )}
            </div>
          ))}
      </>
    </b2x.EqualHeight>
  );
};
