import './ProductsLanding.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { ProductsContentSection } from './contentTypes';
import { SliderProductsLanding } from './slider/SliderProductsLanding';

export interface ProductsLandingProps extends ProductsContentSection {
  className?: string;
}

export const ProductsLanding = ({ elementRowList }: ProductsLandingProps) => {
  return (
    <>
      {elementRowList &&
        elementRowList.map(
          (row) =>
            row.title && (
              <div className="products-landing my-5" key={row.contentSectionId}>
                {(row.title || row.subtitle) && (
                  <b2x.Row className="justify-content-center">
                    <b2x.Col className="px-3" size={{ lg: 10 }}>
                      <div className="products-block px-2 px-lg-0 my-3">
                        {row.title && <h2 className="text-blue fw-normal">{b2x.formatHtml(row.title)}</h2>}
                        {row.subtitle && <h6 className="mt-3 fw-normal">{b2x.formatHtml(row.subtitle)}</h6>}
                      </div>
                    </b2x.Col>
                  </b2x.Row>
                )}
                {row.columnContent?.productsList &&
                  row.columnContent.productsList.length > 0 &&
                  (row.columnContent.img?.src?.xs ? (
                    <b2x.Row
                      className={classnames(
                        {
                          'align-items-center': row.columnContent.productsList.length === 0,
                        },
                        {
                          ' mt-3': row.columnContent.productsList.length > 0,
                        }
                      )}
                    >
                      <b2x.Col
                        className={classnames({ 'order-lg-2': row.columnContent.imagePosition === 'right' })}
                        size={{ lg: row.columnContent.imageSize === 'bigger' ? 7 : 6, xs: 12 }}
                      >
                        <div
                          className={classnames(
                            {
                              'd-lg-block d-none': row.columnContent.productsList.length === 0,
                            },
                            {
                              ' mb-4': row.columnContent.productsList.length > 0,
                            }
                          )}
                        >
                          {row.columnContent.productsList.length > 0 ? (
                            row.rowCta ? (
                              <b2x.DeprecatedCta cta={row.rowCta}>
                                <b2x.ImageFromContentV1 {...row.columnContent.img} className="rounded" fluid />
                              </b2x.DeprecatedCta>
                            ) : (
                              <b2x.ImageFromContentV1 {...row.columnContent.img} className="rounded" fluid />
                            )
                          ) : row.columnContent.cta ? (
                            <b2x.DeprecatedCta cta={row.columnContent.cta}>
                              <b2x.ImageFromContentV1 {...row.columnContent.img} className="rounded" fluid />
                            </b2x.DeprecatedCta>
                          ) : (
                            <b2x.ImageFromContentV1 {...row.columnContent.img} className="rounded" fluid />
                          )}
                        </div>
                      </b2x.Col>

                      <b2x.Col
                        className={classnames(
                          { 'order-lg-1': row.columnContent.imagePosition === 'right' },
                          { 'px-0 px-lg-3': row.columnContent.productsList.length > 0 },
                          { 'px-lg-5': row.columnContent.productsList.length === 0 }
                        )}
                        size={{ lg: row.columnContent.imageSize === 'bigger' ? 5 : 6, xs: 12 }}
                      >
                        {row.columnContent.productsList.length > 0 ? (
                          <b2x.ProductsByIds ids={row.columnContent.productsList.map(({ productId }) => productId)}>
                            {(products) =>
                              products !== undefined && (
                                <b2x.EqualHeight>
                                  <b2x.Listing name="Promotion page - promo products" products={products}>
                                    <SliderProductsLanding numberSlidesLg={2} products={products} />
                                  </b2x.Listing>
                                </b2x.EqualHeight>
                              )
                            }
                          </b2x.ProductsByIds>
                        ) : (
                          <div>
                            {row.columnContent.title && (
                              <h2 className={classnames('text-blue text-lg-start text-center')}>
                                {b2x.formatHtml(row.columnContent.title)}
                              </h2>
                            )}
                            {row.columnContent.preTitle && (
                              <p className="mt-1 text-lg-start text-center">
                                {b2x.formatHtml(row.columnContent.preTitle)}
                              </p>
                            )}

                            <div className="d-lg-none mb-3">
                              {row.columnContent.productsList.length > 0 ? (
                                row.rowCta ? (
                                  <b2x.DeprecatedCta cta={row.rowCta}>
                                    <b2x.ImageFromContentV1 {...row.columnContent.img} className="rounded" fluid />
                                  </b2x.DeprecatedCta>
                                ) : (
                                  <b2x.ImageFromContentV1 {...row.columnContent.img} className="rounded" fluid />
                                )
                              ) : row.columnContent.cta ? (
                                <b2x.DeprecatedCta cta={row.columnContent.cta}>
                                  <b2x.ImageFromContentV1 {...row.columnContent.img} className="rounded" fluid />
                                </b2x.DeprecatedCta>
                              ) : (
                                <b2x.ImageFromContentV1 {...row.columnContent.img} className="rounded" fluid />
                              )}
                            </div>

                            {row.columnContent.description && (
                              <div className="mt-lg-4 mt-3">{b2x.formatHtml(row.columnContent.description)}</div>
                            )}
                            {row.columnContent.cta && (
                              <b2x.Div className="d-grid gap-2 d-lg-block">
                                <b2x.CtaFromContent
                                  ctaProps={{ button: { className: 'mt-lg-5 mt-4 btn-block px-5' } }}
                                  {...row.columnContent.cta}
                                />
                              </b2x.Div>
                            )}
                          </div>
                        )}
                      </b2x.Col>
                    </b2x.Row>
                  ) : (
                    <b2x.Row
                      className={classnames(
                        {
                          'align-items-center': row.columnContent.productsList.length === 0,
                        },
                        {
                          ' mt-3': row.columnContent.productsList.length > 0,
                        }
                      )}
                    >
                      <b2x.Col className="pe-0" size={{ lg: 12, xs: 12 }}>
                        <b2x.ProductsByIds ids={row.columnContent.productsList.map(({ productId }) => productId)}>
                          {(products) =>
                            products !== undefined && (
                              <b2x.EqualHeight>
                                <b2x.Listing name="products landing" products={products}>
                                  <SliderProductsLanding numberSlidesLg={4} products={products} />
                                </b2x.Listing>
                              </b2x.EqualHeight>
                            )
                          }
                        </b2x.ProductsByIds>
                      </b2x.Col>
                    </b2x.Row>
                  ))}
                {row.rowCta?.label && (
                  <div
                    className={classnames('text-center my-5', {
                      'px-3': row.columnContent?.productsList && row.columnContent.productsList.length > 0,
                    })}
                  >
                    <b2x.DeprecatedCta
                      className="text-decoration-underline text-primary fw-bold text-uppercase d-block d-lg-none"
                      cta={row.rowCta}
                    >
                      {b2x.formatHtml(row.rowCta.label)}
                    </b2x.DeprecatedCta>

                    <b2x.CtaFromContent
                      {...row.rowCta}
                      ctaProps={{ button: { className: 'px-5 d-none d-lg-inline-block' } }}
                    />
                  </div>
                )}
              </div>
            )
        )}
    </>
  );
};
