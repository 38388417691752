import { b2x } from '@b2x/react/src';

import { ArticleBox } from '../ArticleBox';
import { Breadcrumb } from '../Breadcrumb';
import { Container } from '../Container';
import { LandingTemplateAPageContentType } from '../contentTypes';
import { MediaBanner } from '../MediaBanner';
import { SliderCardRowSectionLandingPageA } from '../slider/SliderCardRowSectionLandingPageA';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';

export interface LandingTemplateAPageProps {}

export const LandingTemplateAPage = () => {
  const page = b2x.usePage<LandingTemplateAPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  const body = page?.content?.body;

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <MediaBanner
        {...body?.banner}
        mobileCopyPosition="bottom"
        textColSize={{ lg: 8, md: 10, xl: 6, xs: 12 }}
        titleCss="text-white"
      />
      <Container className="mt-4 d-lg-block d-none">
        <Breadcrumb />
      </Container>
      {(body?.firstArticle?.title || body?.firstArticle?.description) && <ArticleBox {...body.firstArticle} />}
      {body?.cardRowList?.map((row) => (
        <SliderCardRowSectionLandingPageA {...row} key={row.contentSectionId} />
      ))}
      {(body?.secondArticle?.title || body?.secondArticle?.description) && <ArticleBox {...body.secondArticle} />}

      {page?.products && page.products.length > 0 && (
        <div className="mt-5 mb-3">
          <b2x.Listing name={`Landing page - ${body?.sliderProductsTitle}`} products={page.products}>
            <SliderProducts products={page.products} title={body?.sliderProductsTitle} />
          </b2x.Listing>
        </div>
      )}
    </Page>
  );
};
