import './BannerSlider.scss';

import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { Banner } from '../Banner';
import { SliderContentSection } from '../contentTypes';
import { Slider } from '../slider/Slider';

export interface BannerSliderProps extends SliderContentSection {}

export const BannerSlider = ({ fullHeight, sliderList }: BannerSliderProps) => {
  return (
    <div className="banner-slider">
      <Slider
        autoplay={{ delay: 4000 }}
        className={classnames({ 'slider-full-height': fullHeight })}
        navigation
        pagination
        slidesPerView={1}
        spaceBetween={0}
        watchSlidesProgress
      >
        {sliderList &&
          sliderList.map((slide) => (
            <SwiperSlide key={slide.contentSectionId}>{(slideData) => <Banner {...slide} />}</SwiperSlide>
          ))}
      </Slider>
    </div>
  );
};
