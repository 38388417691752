import './BlogPage.scss';

import { b2x } from '@b2x/react/src';

import { ArticleTile } from '../ArticleTile';
import { Container } from '../Container';
import { ArticlePageContentType, BlogPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { MediaBanner } from '../MediaBanner';
import { Page } from './Page';

export interface BlogPageProps {
  numArticlesShowed?: number;
}

export const BlogPage = ({ numArticlesShowed = 8 }: BlogPageProps) => {
  const [searchParams] = b2x.router.useSearchParams();
  const pageNum = searchParams.get('pageNum') ? Number(searchParams.get('pageNum')) : 1;

  const page = b2x.usePage<BlogPageContentType>();

  const menu = b2x.useMenu('MENU_MAGAZINE');
  const body = page?.content?.body;

  const { articles, noArticlesFound, pagesCount } = b2x.useArticles<ArticlePageContentType>({
    blogCodePage: 'SITE_MAGAZINE',
    numArticlesShowed: numArticlesShowed,
  });

  return (
    <Page className="page-magazine" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <MediaBanner {...body?.header} />

      {(body?.pageTitle || body?.pageSubtitle) && (
        <Container>
          <div className="mt-5 text-center d-none d-lg-block">
            {body.pageTitle && <h2 className="mb-2 text-blue">{b2x.formatHtml(body.pageTitle)}</h2>}
            {body.pageTitle && <div>{b2x.formatHtml(body.pageSubtitle)}</div>}
          </div>
        </Container>
      )}

      {menu && (
        <div className="d-flex justify-content-md-center my-5 list-links ms-3">
          <b2x.router.NavLink className="btn" end to={menu.link}>
            {t('misc.viewAll')}
          </b2x.router.NavLink>
          {menu.children.map((item) => (
            <b2x.router.NavLink className="btn" end key={item.id} to={item.link}>
              {item.label}
            </b2x.router.NavLink>
          ))}
        </div>
      )}

      {articles && (
        <Container>
          <b2x.EqualHeight>
            <div className="row">
              {articles.map(
                (article, index) =>
                  article.breadcrumb && (
                    <div className="col-lg-3 col-md-4 col-sm-6" key={article.id}>
                      <ArticleTile
                        category={article.breadcrumb[article.breadcrumb.length - 2].name}
                        classname="mb-sm-5 mb-3"
                        cta={article.fullPath}
                        description={article.content?.body.description}
                        img={article.content?.body.imagePreview?.url}
                        title={article.content?.body.title}
                      />
                    </div>
                  )
              )}
            </div>
            {/* {otherItemsToShow && (
              <div className="text-center mb-5">
                <Button
                  className="btn btn-brimary"
                  disabled={fetching}
                  label={t('misc.moreArticles')}
                  onClick={fetchMore}
                />
              </div>
            )} */}
          </b2x.EqualHeight>
          <b2x.Pagination
            alignment="center"
            currentPage={pageNum}
            pageOffset={2}
            showDots
            singleStepNavigation
            totalPages={pagesCount}
          />
        </Container>
      )}
      {noArticlesFound && <div className="text-center my-3">{t('misc.noItemFound')}</div>}

      {/* {fetching && <div className="text-center my-3">Loading...</div>} */}
    </Page>
  );
};
