import './ImageSlider.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Button } from '../Button';
import { Container } from '../Container';
import { ImageSliderContentType } from '../contentTypes';

interface ImageSliderProps extends ImageSliderContentType {}

export const ImageSlider = ({ list }: ImageSliderProps) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const currentBreakpoint = b2x.useBreakpoint();
  const isMobile = b2x.untilBreakpoint('md', currentBreakpoint);
  console.log('isMobile', isMobile);

  return (
    <div className="image-slider">
      <Container>
        <b2x.Row>
          <b2x.Col>
            <b2x.Div
              className="h-100"
              display={{ lg: 'flex', xs: 'none' }}
              flexDirection="column"
              gap={{ lg: 4, xs: 3 }}
              justifyContent="center"
            >
              {list?.at(currentSlide)?.title && (
                <b2x.H2 className="title" textVariant="blue">
                  {b2x.formatHtml(list.at(currentSlide)?.title)}
                </b2x.H2>
              )}
              {list?.at(currentSlide)?.subtitle && (
                <div className="subtitle">{b2x.formatHtml(list.at(currentSlide)?.subtitle)}</div>
              )}
            </b2x.Div>
          </b2x.Col>
          <b2x.Col
            size={{
              lg: 6,
              xs: 12,
            }}
          >
            <b2x.EqualHeight>
              <b2x.SwiperContext>
                {({ swiper }) => (
                  <b2x.Swiper
                    centeredSlides
                    initialSlide={currentSlide}
                    navigation={{ custom: true }}
                    /* eslint-disable-next-line */
                    onRealIndexChange={({ realIndex }: b2x.SwiperClass) => setCurrentSlide(realIndex)}
                    pagination={isMobile}
                    slides={list?.map(({ contentSectionId, img, subtitle, title }) => (
                      <b2x.Div key={contentSectionId} marginY={4}>
                        <div className="img-slider-col">
                          {!isMobile && (
                            <>
                              <Button
                                className="swiper-prev text-primary"
                                disabled={swiper?.isBeginning}
                                iconStart={{ name: 'large-arrow-left', size: 72 }}
                                variant="blank"
                                /* eslint-disable-next-line */
                                onClick={() => swiper?.slidePrev()}
                              />
                              <Button
                                className="swiper-next text-primary"
                                disabled={swiper?.isEnd}
                                iconStart={{ name: 'large-arrow-right', size: 72 }}
                                variant="blank"
                                /* eslint-disable-next-line */
                                onClick={() => swiper?.slideNext()}
                              />
                            </>
                          )}
                          <div className="img-container">{img && <b2x.ImageFromContentV1 {...img} fluid />}</div>
                        </div>

                        <b2x.Div
                          display={{ lg: 'none', xs: 'flex' }}
                          flexDirection="column"
                          gap={{ lg: 4, xs: 3 }}
                          justifyContent="center"
                          marginTop={3}
                        >
                          {title && (
                            <b2x.H2 className="title" fontWeight={600} marginBottom={0} textVariant="blue">
                              {b2x.formatHtml(title)}
                            </b2x.H2>
                          )}
                          {subtitle && <div className="subtitle">{b2x.formatHtml(subtitle)}</div>}
                        </b2x.Div>
                      </b2x.Div>
                    ))}
                  />
                )}
              </b2x.SwiperContext>
            </b2x.EqualHeight>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};
