import './SliderProducts.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { ProductTile } from '../ProductTile';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';

export interface SliderProductsLandingProps {
  className?: string;
  numberSlidesLg?: number;
  products?: Array<b2x.ProductApiDto>;
  title?: string;
}

export const SliderProductsLanding = ({
  className,
  numberSlidesLg = 4,
  products,
  title,
}: SliderProductsLandingProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  return (
    <div className={classnames('slider-products', className)}>
      {title && (
        <div className="row">
          <div className="col-12">
            <div className="text-center mb-2">
              <h3 className="h2 text-blue">{title && b2x.formatHtml(title)}</h3>
            </div>
          </div>
        </div>
      )}

      <div
        className={classnames(
          'pt-0 px-lg-3',
          { 'pe-0': products && products.length > 1 },
          { 'swiper-products': products && products.length < 4 }
        )}
      >
        <b2x.EqualHeight>
          <div className="slider-container position-relative">
            <Slider
              navigation
              responsive={{
                lg: {
                  slidesPerView: numberSlidesLg,
                },
                md: {
                  slidesPerView: 2.2,
                },
              }}
              sliderNextEl={sliderNextButton}
              sliderPrevEl={sliderPrevButton}
              slidesPerView={products?.length === 1 ? 1 : 1.2}
              spaceBetween={20}
            >
              {products?.map((product) => (
                <SwiperSlide key={product.id}>
                  <ProductTile className="my-0" enableExtraInfo product={product} />
                </SwiperSlide>
              ))}
            </Slider>

            <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
            <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
          </div>
        </b2x.EqualHeight>
      </div>
    </div>
  );
};
