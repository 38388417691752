import { DirectoryApiDto } from '@b2x/storefront-api-js-client/src';
import { GetDirectoryOptions } from '@b2x/storefront-api-js-client/src/directories';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useDirectoriesApi } from './api/useDirectoriesApi';
import { useStable } from './util';

export interface UseDirectoryByCodeOptions extends GetDirectoryOptions {}

export const useDirectory = <T,>(code: string, options?: UseDirectoryByCodeOptions, config?: ApiRequestConfig) => {
  const [directory, setDirectory] = React.useState<DirectoryApiDto<T>>();

  const { getDirectoryByCode } = useDirectoriesApi();

  options = useStable(options);
  config = useStable(config);

  React.useEffect(() => {
    getDirectoryByCode<T>(code, options, config)
      .then((response) => {
        setDirectory(response.data);
      })
      .catch(() => {
        // pazienza
      });
  }, [code, config, getDirectoryByCode, options]);

  return directory;
};
