import { b2x } from '@b2x/react/src';

import { LandingPromotionContentType } from '../contentTypes';
import { DoubleColumn } from '../DoubleColumn';
import { HeaderLanding } from '../HeaderLanding';
import { Page } from './Page';

export interface PromotionPageProps {}
export const PromotionPage = (props: PromotionPageProps) => {
  const page = b2x.usePage<LandingPromotionContentType>();

  const body = page?.content?.body;
  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <HeaderLanding {...body?.header} textColSize={{ md: 10, xl: 8, xs: 12 }} />

      {body?.elementRowList && body.elementRowList.map((row) => <DoubleColumn key={row.contentSectionId} {...row} />)}
    </Page>
  );
};
