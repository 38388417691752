import './css/style.scss';
import './i18n/i18n';

import { b2x } from '@b2x/react/src';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { BestPrice } from './BestPrice';
import { Breadcrumb } from './Breadcrumb';
import { Button, ButtonVariant } from './Button';
import { CartSku } from './CartSku';
import { Container } from './Container';
import { NewsletterForm } from './forms/NewsletterForm';
import { en } from './i18n/locales/en';
import { it } from './i18n/locales/it';
import { IconName, IconSize } from './Icon';
import { LoadingOverlay } from './LoadingOverlay';
import { OffcanvasHeader } from './Offcanvas';
import { ThemeColor } from './pages/TemplatePage';
import { PriceBlock } from './PriceBlock';
import { ProductTile, ProductTileBasicInfo, ProductTileExtraInfo } from './ProductTile';
import { reportWebVitals } from './reportWebVitals';

b2x.init<ThemeColor, ButtonVariant, IconName, IconSize>({
  api: {
    apiKey: 'GHCJAFCAABGHIEGBDEHBEJ',
    debug: false,
    productPopulate: {
      alternativeImages: true,
      attributeGroups: true,
      attributes: true,
      content: true,
      priceRange: true,
      skus: {
        attributes: true,
        price: true,
      },
      variantProducts: true,
    },
    productTilePopulate: {
      attributes: true,
      priceRange: true,
      skus: {
        attributes: true,
        price: true,
      },
      variantProducts: true,
    },
    sessionOptions: {
      populate: {
        cart: {
          packs: {
            cartSkus: {
              giftCard: true,
              sku: {
                attributes: true,
                product: {
                  attributes: true,
                },
              },
            },
          },
          shippingProfile: true,
        },
        customer: true,
      },
    },
  },
  consentMode: false,
  cookiebot: {
    id: '879a22f4-082f-404b-9634-60eab7cf3fb1',
  },
  defaultButtonVariant: 'primary',
  enableBestPrice: true,
  enableTaxBenefits: true,
  enableWishlist: false,
  form: {
    buttons: {
      cancel: {
        defaultVariant: 'outline-primary',
      },
      submit: {
        defaultVariant: 'primary',
      },
    },
  },
  i18n: {
    en: en,
    it: it,
  },
  icons: {
    arrow: {
      left: 'small-arrow-left',
    },
    close: 'delete',
    configurator: 'drop',
    download: 'download',
    info: 'info',
    logout: 'exit',
  },
  keepUselessLocaleTokenInPath: true,
  projectName: 'hartmann',
  ui: 'bs5',
  variants: {
    AccountLoyaltySubpage: 'B',
    AddressFieldset: 'A',
    BestPrice: BestPrice,
    Breadcrumb: Breadcrumb,
    Button: Button,
    CartAddressFromAddressBookForm: 'A',
    CartAddressesForm: 'A',
    CartSku: CartSku,
    ChangePasswordForm: 'A',
    Container: Container,
    CustomerAddressForm: 'A',
    CustomerForm: 'A',
    InvoiceFieldset: 'A',
    LoadingOverlay: LoadingOverlay,
    LocaleForm: 'A',
    LoginForm: 'A',
    NewsletterForm: NewsletterForm,
    OffcanvasHeader: OffcanvasHeader,
    OrderGuestForm: 'A',
    PriceBlock: PriceBlock,
    ProductTile: ProductTile,
    ProductTileBasicInfo: ProductTileBasicInfo,
    ProductTileExtraInfo: ProductTileExtraInfo,
    SearchFilters: 'A',
    SimpleSearchForm: 'A',
    SmartOrderForm: 'A',
    TaxBenefitsForm: 'A',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <b2x.router.BrowserRouter>
      <b2x.ModalsContext>
        <App />
      </b2x.ModalsContext>
    </b2x.router.BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
