import { b2x } from '@b2x/react/src';

import { useAppStaticContext } from '../AppContext';
import { Button } from '../Button';
import { Container } from '../Container';
import { LandingTemplateBPageContentType } from '../contentTypes';
import { MediaBanner } from '../MediaBanner';
import { SliderContentC } from '../slider/SliderContentC';
import { Page } from './Page';

export interface LandingTemplateBPageProps {}

export const LandingTemplateBPage = (props: LandingTemplateBPageProps) => {
  const { session } = b2x.useAppContext();
  const { showAccountOffcanvas } = useAppStaticContext();

  const page = b2x.usePage<LandingTemplateBPageContentType>();

  const body = page?.content?.body;
  const header = body?.header;
  const cta = body?.cta;

  return (
    <Page noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <MediaBanner {...header} />
      <SliderContentC {...page?.content?.body.stepsCategorySlider} />
      <Container>
        {session?.customer
          ? cta &&
            cta.loggedIn && (
              <div className="d-flex flex-column justify-content-center text-center align-items-center mb-5 pb-4">
                <b2x.DeprecatedCta className="fw-bold text-decoration-none" cta={cta.loggedIn}>
                  {cta.loggedIn.label}
                </b2x.DeprecatedCta>
              </div>
            )
          : cta && (
              <div className="d-flex flex-column justify-content-center text-center align-items-center mb-5 pb-4">
                {cta.registration && (
                  <b2x.DeprecatedCta className="btn btn-primary mb-3" cta={cta.registration}>
                    {cta.registration.label}
                  </b2x.DeprecatedCta>
                )}
                {cta.login && (
                  <Button
                    className="fw-bold text-decoration-none"
                    label={cta.login.label}
                    onClick={showAccountOffcanvas}
                    variant="link"
                  />
                )}
              </div>
            )}

        <b2x.Row className="justify-content-center">
          <b2x.Col size={{ lg: 10, xs: 12 }}>
            {body?.disclaimer && (
              <h4 className="text-center mb-5 text-blue fw-bold">{b2x.formatHtml(body.disclaimer)}</h4>
            )}
            {body?.title && <h2 className="text-center mb-5 text-blue fw-normal">{b2x.formatHtml(body.title)}</h2>}
            {body?.description && <p className="mb-5">{b2x.formatHtml(body.description)}</p>}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </Page>
  );
};
