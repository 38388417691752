import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { SectionQuoteSection } from './contentTypes';
import { Icon } from './Icon';

export interface SectionQuoteProps extends SectionQuoteSection {
  className?: string;
}

export const SectionQuote = ({ backgroundColor, content, note, title }: SectionQuoteProps): React.ReactElement => {
  return (
    // <div className={classNames(`bg-${backgroundColor} text-start`)}>
    <b2x.Row className={classNames(`bg-${backgroundColor} text-start align-items-center justify-content-center py-5`)}>
      <b2x.Col className="px-5" size={{ lg: 6, xs: 12 }}>
        <div className="h1 fw-bold text-white">{b2x.formatHtml(title)}</div>
      </b2x.Col>
      <b2x.Col className="px-5 mt-5 mt-lg-0" size={{ lg: 6, xs: 12 }}>
        <Icon className="d-block text-yellow-light mb-3" name="quote" size={48} />
        <div className="display-5 fw-normal text-white"> {b2x.formatHtml(content)}</div>
        {note && <div className="h5 fw-normal fst-italic text-white mt-4"> {b2x.formatHtml(note)}</div>}
      </b2x.Col>
    </b2x.Row>
    // </div>
  );
};
