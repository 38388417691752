import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType, HeaderMenuItemContentType } from './contentTypes';
import { Icon } from './Icon';
import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';

//const fadeDuration = 200;

export interface DesktopHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  firstRowRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({ content, firstRowRef, recalculateHeaderHeight, visibleFrom }: DesktopHeaderProps) => {
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();

  const menu = b2x.useMenu('MENU_HEADER_DESKTOP', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, []);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return (
    <>
      <FirstRow
        activeCategory={activeCategory}
        content={content}
        innerRef={firstRowRef}
        localeBoxVisible={localeBoxVisible}
        menu={menu}
        recalculateHeaderHeight={recalculateHeaderHeight}
        searchBoxVisible={searchBoxVisible}
        setActiveCategory={setActiveCategory}
        toggleLocaleBoxVisible={toggleLocaleBoxVisible}
        toggleSearchBoxVisible={toggleSearchBoxVisible}
        visibleFrom={visibleFrom}
      />
    </>
  );
};

interface FirstRowProps {
  activeCategory?: b2x.MenuApiDto;
  content?: b2x.ContentApiDto<HeaderContentType>;
  innerRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  menu?: b2x.MenuApiDto;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  toggleLocaleBoxVisible(): void;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const FirstRow = ({
  activeCategory,
  content,
  innerRef,
  localeBoxVisible,
  menu,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  toggleLocaleBoxVisible,
  toggleSearchBoxVisible,
  visibleFrom,
}: FirstRowProps) => {
  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classnames('header-first-row-wrapper sticky-top', `d-none d-${visibleFrom}-block`)} ref={innerRef}>
      <div className={classnames('header-first-row', { shadow: !searchBoxVisible && !localeBoxVisible })}>
        <Container>
          <b2x.Row className="justify-content-start">
            <b2x.Col className="d-flex col-auto py-3">
              <Logo recalculateHeaderHeight={recalculateHeaderHeight} />
            </b2x.Col>
            <b2x.Col className="d-flex flex-grow-0 align-items-center">
              <Menu
                activeCategory={activeCategory}
                menu={menu}
                setActiveCategory={setActiveCategory}
                subMenuDropdownRef={subMenuDropdownRef}
              />
            </b2x.Col>
            <b2x.Col className="d-flex align-items-center justify-content-end py-3">
              <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
      {activeCategory && (
        <SubMenuDropdown
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subMenuDropdownRef={subMenuDropdownRef}
        />
      )}
      {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
      {/* {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />} */}
    </div>
  );
};

// interface LocaleToggleProps {
//   toggleLocaleBoxVisible(): void;
// }

// const LocaleToggle = ({ toggleLocaleBoxVisible }: LocaleToggleProps) => {
//   const { locale, shippingCountry } = b2x.useAppContext();

//   return (
//     <Button
//       alignItems="center"
//       className="text-decoration-none"
//       iconStart={{ name: 'traslate', size: 25 }}
//       label={
//         locale &&
//         shippingCountry && (
//           <span>{t('header.sendTo', { country: shippingCountry.name, locale: locale.languageDescriptionLocal })}</span>
//         )
//       }
//       onClick={toggleLocaleBoxVisible}
//       size="small"
//       variant="blank"
//     />
//   );
// };

interface TogglesProps {
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      <div className="hstack gap-2">
        <Button iconEnd={{ name: 'search', size: 25 }} onClick={toggleSearchBoxVisible} variant="blank" />
        {session?.customer ? (
          <b2x.router.Link className="lh-1 text-secondary" to={getPagePath('SITE_ACCOUNT')}>
            <Icon name="profile" size={25} />
          </b2x.router.Link>
        ) : (
          <Button iconEnd={{ name: 'profile', size: 25 }} onClick={showAccountOffcanvas} variant="blank" />
        )}

        {b2x.appConfig.enableWishlist &&
          (session?.customer ? (
            <b2x.router.Link className="lh-1" to="/account/area/wishlist">
              <Button className="position-relative" variant="blank">
                <Icon name={'wishlist'} size={25} />
                {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                  (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                  <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-secondary">
                    {(session.wishlist.products ? session.wishlist.products.length : 0) +
                      (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                  </span>
                )}
              </Button>
            </b2x.router.Link>
          ) : (
            <Button iconEnd={{ name: 'wishlist', size: 25 }} onClick={showAccountOffcanvas} variant="blank" />
          ))}

        <Button className="position-relative" onClick={showCartOffcanvas} variant="blank">
          <Icon name={'cart'} size={25} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-secondary">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </>
  );
};

interface LogoProps {
  recalculateHeaderHeight(): void;
  small?: boolean;
}

const Logo = ({ recalculateHeaderHeight, small }: LogoProps) => {
  return (
    <b2x.router.Link to="/">
      <b2x.Image onLoad={recalculateHeaderHeight} src={small ? logoSmall : logo} />
    </b2x.router.Link>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item d-flex align-items-center mx-2', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <Button
        className={classnames('text-decoration-none fw-bold text-blue text-uppercase p-0 text-nowrap', {
          active: activeCategory && activeCategory.id === firstLevelCategory.id,
        })}
        label={firstLevelCategory.label}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}
        size="small"
        variant="blank"
      />
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({ activeCategory, menu, setActiveCategory, subMenuDropdownRef }: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
    }
  }, [idActiveCategory, setActiveCategory]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 100);

  return (
    <div className="menu d-flex h-100">
      {menu?.children.map(
        (firstLevelCategory) =>
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              {firstLevelCategory.image?.src ? (
                <b2x.router.Link
                  className={classnames(
                    'Button btn btn-link blank btn-sm text-decoration-none text-blue fw-semi-bold p-0 text-nowrap',
                    {
                      active: activeCategory && activeCategory.id === firstLevelCategory.id,
                    }
                  )}
                  to={firstLevelCategory.link}
                >
                  <b2x.Image src={firstLevelCategory.image.src} />
                </b2x.router.Link>
              ) : (
                <b2x.router.Link
                  className={classnames(
                    'Button btn btn-link blank btn-sm text-decoration-none text-blue fw-semi-bold p-0 text-nowrap',
                    {
                      active: activeCategory && activeCategory.id === firstLevelCategory.id,
                    }
                  )}
                  to={firstLevelCategory.link}
                >
                  {firstLevelCategory.label}
                </b2x.router.Link>
              )}
            </MenuItemContainer>
          ))
      )}
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <div
      className={classnames({ mounted: isMounted }, 'sub-menu-dropdown shadow-sm w-100 py-5')}
      ref={subMenuDropdownRef}
    >
      <Container>
        <b2x.Row className="justify-content-center">
          {/* {activeCategory.id} */}
          <b2x.Col size={{ lg: 9, xl: 7 }}>
            <b2x.Row className="justify-content-start" cols={3}>
              {activeCategory.children.map((secondLevelCategory) => (
                <b2x.Col className="d-flex flex-column" key={secondLevelCategory.id}>
                  {secondLevelCategory.content &&
                    b2x.typedContent<HeaderMenuItemContentType>(secondLevelCategory.content, (content) => (
                      <>
                        {content.body.img && (
                          <div className="mb-4">
                            <b2x.ImageFromContentV1 fluid {...content.body.img} />
                          </div>
                        )}
                        <div className="fw-bold text-uppercase text-center">
                          {content.body.cta && (
                            <b2x.DeprecatedCta className="text-reset text-decoration-none" cta={content.body.cta}>
                              {content.body.titleA}
                              <span className="text-primary"> {content.body.titleB}</span>
                            </b2x.DeprecatedCta>
                          )}
                        </div>
                      </>
                    ))}
                  {secondLevelCategory.children.length > 0 && (
                    <ul className="list-unstyled">
                      {secondLevelCategory.children.map((thirdLevelCategory) => (
                        <li key={thirdLevelCategory.id}>
                          {thirdLevelCategory.children.length > 0 ? (
                            <div className="mb-3">
                              <h6 className="small text-uppercase text-blue">
                                <b2x.router.Link
                                  className="text-reset text-decoration-none"
                                  onClick={close}
                                  to={thirdLevelCategory.link}
                                >
                                  {thirdLevelCategory.label}
                                </b2x.router.Link>
                              </h6>
                              <ul className="list-unstyled">
                                {thirdLevelCategory.children.map(
                                  (fourLevelCategory) =>
                                    fourLevelCategory.link && (
                                      <li key={fourLevelCategory.id}>
                                        <b2x.router.Link
                                          className="text-reset text-decoration-none"
                                          onClick={close}
                                          to={fourLevelCategory.link}
                                        >
                                          <span className="fw-light small">{fourLevelCategory.label}</span>
                                        </b2x.router.Link>
                                      </li>
                                    )
                                )}
                              </ul>
                            </div>
                          ) : (
                            <div>
                              <b2x.router.Link
                                className={classnames(
                                  'text-decoration-none d-table',
                                  thirdLevelCategory.tags?.includes('showAll')
                                    ? 'h6 small text-uppercase fw-bold text-blue'
                                    : 'text-reset '
                                )}
                                onClick={close}
                                to={thirdLevelCategory.link}
                              >
                                {thirdLevelCategory.label}
                              </b2x.router.Link>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </b2x.Col>
              ))}
            </b2x.Row>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleSearchBoxVisible}>
      <div className="row justify-content-center">
        <div className="col-8">
          <b2x.SimpleSearchForm onSuccess={toggleSearchBoxVisible} />
        </div>
      </div>
    </Box>
  );
};

// interface LocaleBoxProps {
//   toggleLocaleBoxVisible(): void;
// }

// const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
//   return (
//     <Box onCloseButtonClick={toggleLocaleBoxVisible}>
//       <div className="row justify-content-center text-center">
//         <div className="col-5">
//           <LocaleForm />
//         </div>
//       </div>
//     </Box>
//   );
// };

interface BoxProps {
  children?: React.ReactNode;
  onCloseButtonClick(): void;
}

const Box = ({ children, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className="box py-5 shadow" ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button iconEnd={{ name: 'delete', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
        {children}
      </Container>
    </div>
  );
};
