import './LandingTemplateEPage.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';
import React from 'react';

import { useAppStaticContext } from '../AppContext';
import { Button } from '../Button';
import { Container } from '../Container';
import { LandingTemplateEPageContentType } from '../contentTypes';
import { HeaderLanding } from '../HeaderLanding';
import { t } from '../i18n/i18n';
import { Section } from '../Section';
import { Page } from './Page';

export interface LandingTemplateEPagePageProps {}

const DisplayProduct = ({
  product,
  quantityOptions,
  setSelectedSkus,
}: {
  product: b2x.ProductApiDto;
  quantityOptions: Array<JSX.Element>;
  setSelectedSkus: React.Dispatch<React.SetStateAction<Array<b2x.SmartOrderSkuContentType>>>;
}) => {
  const absorbency = product.attributes?.find((attribute) => attribute.typeCode === 'HAR_Gocce');

  const handleSkuChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const val = parseInt(e.target.value);
      const skuId = product.skus?.at(0)?.id;
      const skuPrice = product.skus?.at(0)?.price?.value;

      if (!skuId || skuPrice === undefined) {
        return;
      }

      setSelectedSkus((prev) => {
        const i = prev.findIndex((sku) => sku.id === skuId);
        if (i === -1) {
          return [...prev, { id: skuId, price: skuPrice, quantity: val }];
        }
        prev[i].quantity = val;
        return [...prev];
      });
    },
    [product.skus, setSelectedSkus]
  );

  return (
    <b2x.Col key={product.id} size={6}>
      <b2x.Div display="flex" flexDirection="column">
        <b2x.EqualHeightElement name="asset">
          <b2x.Image
            alt={product.name}
            aspectRatio={b2x.appConfig.productImageAspectRatio}
            className="rounded w-100"
            fluid
            src={product.image?.src}
            style={{ maxWidth: 300 }}
          />
        </b2x.EqualHeightElement>
        <b2x.EqualHeightElement name="details">
          {product.name && (
            <b2x.H6 marginTop={3} textVariant="blue">
              {b2x.formatHtml(product.name)}
            </b2x.H6>
          )}
          {absorbency?.value && (
            <div className="absorbency mb-2 d-flex flex-wrap">
              <div className="drop-container lh-1 me-1">
                {[...Array(10)].map((e, i) => (
                  <div
                    className={classNames('drop', {
                      'drop-active': absorbency.value && parseInt(absorbency.value) > i,
                    })}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`absorbency-${i}`}
                  ></div>
                ))}
              </div>
              <div className="ps-a extra-small text-gray-400">
                {absorbency.value} {absorbency.title}
              </div>
            </div>
          )}
        </b2x.EqualHeightElement>
        <b2x.EqualHeightElement name="sku">
          {product.skus?.at(0) && <b2x.P className="small text-gray-400">{product.skus.at(0)?.name}</b2x.P>}
        </b2x.EqualHeightElement>
        {!!product.skus?.length && (
          <b2x.FormGroup label="Scegli il numero di confezioni" names={[`select-sku-${product.skus.at(0)?.id}`]}>
            <b2x.Select
              defaultValue="0"
              includeEmptyOption={false}
              name={`select-sku-${product.skus.at(0)?.id}`}
              onChange={handleSkuChange}
            >
              {quantityOptions}
            </b2x.Select>
          </b2x.FormGroup>
        )}
      </b2x.Div>
    </b2x.Col>
  );
};

export const LandingTemplateEPage = (props: LandingTemplateEPagePageProps) => {
  const page = b2x.usePage<LandingTemplateEPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });
  // const campioneOmaggioDirectory = b2x.useDirectory('REDEEM_FREE_SAMPLE', {
  //   populate: { products: b2x.appConfig.api?.productTilePopulate },
  // });

  const body = page?.content?.body;
  const MAX_QUANTITY = body?.form?.maxQuantity || 0;
  const [selectedSkus, setSelectedSkus] = React.useState<Array<b2x.SmartOrderSkuContentType>>([]);
  const selectedSkusQuantity = selectedSkus.reduce((acc, sku) => acc + sku.quantity, 0);

  const { session } = b2x.useAppContext();
  const isLogged = session?.userLogged;

  const { showAccountOffcanvas } = useAppStaticContext();

  const quantityOptions = React.useMemo(() => {
    return Array.from({ length: MAX_QUANTITY + 1 }, (_, i) => <b2x.Option key={i} label={`${i}`} value={`${i}`} />);
  }, [MAX_QUANTITY]);

  const selectedSkusDescription = React.useMemo(() => {
    return selectedSkus
      .filter((sku) => sku.quantity > 0)
      .map((sku) => ` ${sku.quantity} ${page?.products?.find((p) => p.skus?.at(0)?.id === sku.id)?.name}`)
      .join(' e ');
  }, [selectedSkus, page?.products]);

  return (
    <Page className="landing-template-e-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {body?.header && <HeaderLanding {...body.header} textColSize={{ md: 10, xl: 8, xs: 12 }} />}

      <Section className="free-sample-form-section" noContainer>
        <b2x.Div marginBottom={3}>
          <b2x.H2 textAlign="center" textVariant="blue">
            {t('freeSample.form.selectSample', {
              count: MAX_QUANTITY,
            })}
          </b2x.H2>
          <b2x.Div display="flex" gap={3} justifyContent="center" marginTop={3} paddingX={3}>
            <b2x.EqualHeight>
              <b2x.Row>
                {page?.products?.map((product) => (
                  <DisplayProduct
                    key={product.id}
                    product={product}
                    quantityOptions={quantityOptions}
                    setSelectedSkus={setSelectedSkus}
                  />
                ))}
              </b2x.Row>
            </b2x.EqualHeight>
          </b2x.Div>
          {selectedSkusQuantity > MAX_QUANTITY && (
            <b2x.P textAlign="center" textVariant="promo">
              {t('freeSample.form.maxQuantity', {
                count: MAX_QUANTITY,
              })}
            </b2x.P>
          )}
        </b2x.Div>
        <b2x.Div background="#F2F6FF" paddingY={5}>
          <Container>
            <b2x.Div display="flex" flexDirection="column" gap={2} marginBottom={4}>
              <b2x.H2 margin={0} textAlign="center" textVariant="blue">
                {t('freeSample.form.insertData')}
              </b2x.H2>
              {!isLogged && (
                <b2x.Span margin={0} textAlign="center">
                  {t('freeSample.registration.guestTitle')}
                  <Button label={t('freeSample.registration.login')} onClick={showAccountOffcanvas} variant="link" />
                </b2x.Span>
              )}
              {selectedSkusQuantity > 0 && (
                <b2x.H5 margin={0} textAlign="center" textVariant="blue">
                  <b2x.Span fontWeight={400}>{t('freeSample.form.selected')}</b2x.Span>
                  {selectedSkusDescription}
                </b2x.H5>
              )}
            </b2x.Div>
            <b2x.SmartOrderForm couponCode={body?.form?.couponCode} maxQuantity={MAX_QUANTITY} skus={selectedSkus} />
          </Container>
        </b2x.Div>
      </Section>
    </Page>
  );
};
