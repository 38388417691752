import { Button } from '../Button';
import { Col, ResponsiveColSize } from '../Col';
import { Select } from '../form/fields/Select';
import { Div } from '../HTMLElement';
import { Row } from '../Row';
import { DynamicField } from './fields/DynamicField';
import { PasswordInput, TextInput } from './fields/Input';
import { Checkbox } from './fields/RadioCheck';
import { FormGroup } from './FormGroup';
import { SmartOrderFormHelper, SmartOrderFormProps } from './SmartOrderForm';

const COLUMN_SIZE: ResponsiveColSize = { sm: 6, xl: 4, xs: 12 };

export const SmartOrderFormA = (props: SmartOrderFormProps) => {
  return (
    <SmartOrderFormHelper
      validationSchemaSelector={{
        address: {
          addressLine2: false,
          addressLine3: false,
          alternativePhone: false,
          latitude: false,
          longitude: false,
          middleName: false,
          phone: true,
          region: false,
        },
        email: true,
        marketingConsent: false,
        name: true,
        newsletterConsent: false,
        password: true,
        profilingConsent: false,
        surname: true,
      }}
      {...props}
    >
      {({ fieldsHelper }) => (
        <>
          <Row>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.name.formGroup}>
                <TextInput {...fieldsHelper.name.textInput} />
              </FormGroup>
            </Col>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.surname.formGroup}>
                <TextInput {...fieldsHelper.surname.textInput} />
              </FormGroup>
            </Col>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.email.formGroup}>
                <TextInput {...fieldsHelper.email.textInput} />
              </FormGroup>
            </Col>
            {fieldsHelper.password && (
              <Col size={COLUMN_SIZE}>
                <FormGroup {...fieldsHelper.password.formGroup}>
                  <PasswordInput {...fieldsHelper.password.passwordInput} />
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.address.addressLine1.formGroup}>
                <TextInput {...fieldsHelper.address.addressLine1.textInput} />
              </FormGroup>
            </Col>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.address.civicNumber.formGroup}>
                <TextInput {...fieldsHelper.address.civicNumber.textInput} />
              </FormGroup>
            </Col>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.address.addressLine2.formGroup}>
                <TextInput {...fieldsHelper.address.addressLine2.textInput} />
              </FormGroup>
            </Col>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.address.city.formGroup}>
                <TextInput {...fieldsHelper.address.city.textInput} />
              </FormGroup>
            </Col>
            {fieldsHelper.address.province && (
              <Col size={COLUMN_SIZE}>
                <FormGroup {...fieldsHelper.address.province.formGroup}>
                  <Select {...fieldsHelper.address.province.select} />
                </FormGroup>
              </Col>
            )}
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.address.zipCode.formGroup}>
                <DynamicField {...fieldsHelper.address.zipCode.dynamicField} />
              </FormGroup>
            </Col>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.address.country.formGroup}>
                <Select {...fieldsHelper.address.country.select} />
              </FormGroup>
            </Col>
            <Col size={COLUMN_SIZE}>
              <FormGroup {...fieldsHelper.address.phone.formGroup}>
                <TextInput {...fieldsHelper.address.phone.textInput} />
              </FormGroup>
            </Col>
          </Row>
          {fieldsHelper.marketingConsent && (
            <FormGroup {...fieldsHelper.marketingConsent.formGroup}>
              <Checkbox {...fieldsHelper.marketingConsent.checkbox} />
            </FormGroup>
          )}
          {fieldsHelper.profilingConsent && (
            <FormGroup {...fieldsHelper.profilingConsent.formGroup}>
              <Checkbox {...fieldsHelper.profilingConsent.checkbox} />
            </FormGroup>
          )}
          {fieldsHelper.newsletterConsent && (
            <FormGroup {...fieldsHelper.newsletterConsent.formGroup}>
              <Checkbox {...fieldsHelper.newsletterConsent.checkbox} />
            </FormGroup>
          )}
          <Div marginX="auto" style={{ width: 'fit-content' }}>
            <Button {...fieldsHelper.buttons.submit} />
          </Div>
        </>
      )}
    </SmartOrderFormHelper>
  );
};
