import { b2x } from '@b2x/react/src';

import { FooterIconsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';

export interface FooterIconsProps {
  className?: string;
}

export const FooterIcons = ({ className }: FooterIconsProps) => {
  const footerIconsContent = b2x.useContent<FooterIconsContentType>('FOOTER_ICONS_CONTENT');
  return (
    <>
      {footerIconsContent?.body.iconsList && (
        <b2x.Row className="justify-content-center" gap={0}>
          <b2x.Col size={{ xl: 10, xs: 12 }}>
            <b2x.Row>
              {footerIconsContent.body.iconsList.map((item) => {
                return (
                  item.name &&
                  isIconName(item.name) && (
                    <b2x.Col className="mt-2" key={item.iconTitle} size={3}>
                      {item.url ? (
                        <b2x.router.Link
                          className="d-flex align-items-center text-white text-reset text-decoration-none"
                          to={item.url}
                        >
                          <Icon fontSize={24} name={item.name as IconName} />
                          <h6 className="fw-bold text-uppercase ps-2 m-0">{item.title}</h6>
                        </b2x.router.Link>
                      ) : (
                        <div className="d-flex align-items-center">
                          <Icon fontSize={24} name={item.name as IconName} />
                          <h6 className="fw-bold text-uppercase ps-2 m-0">{item.title}</h6>
                        </div>
                      )}
                    </b2x.Col>
                  )
                );
              })}
            </b2x.Row>
          </b2x.Col>
        </b2x.Row>
      )}
    </>
  );
};
