import './CustomRadio.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

// import { formatSizeAttribute } from './util';

export interface CustomRadioProps {
  isSquare?: boolean;
  sku: b2x.SkuApiDto;
}

export const CustomRadio = ({ isSquare, sku, ...props }: CustomRadioProps) => {
  const size = sku.attributes?.find((attribute) => attribute.typeCode === 'HAR_TAGLIA');

  return (
    <div className={classnames('CustomRadio d-flex align-items-center justify-content-center px-2')}>
      <span className="lh-1 pt-1 small d-none d-lg-inline">{size?.valueCode?.split('HAR_TAG_')[1]}</span>
      <span className="lh-1 pt-1 extra-small d-lg-none">{size?.valueCode?.split('HAR_TAG_')[1]}</span>
    </div>
  );
};
