import './AboutUsPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';
import { AboutUsPageContentType } from '../contentTypes';
import { HeaderLanding } from '../HeaderLanding';
import { SectionQuote } from '../SectionQuote';
import { Page } from './Page';

export interface AboutUsPagePros {}

export const AboutUsPage = (props: AboutUsPagePros) => {
  const page = b2x.usePage<AboutUsPageContentType>();

  return (
    <Page className="about-us-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <HeaderLanding {...page?.content?.body.header} textColSize={{ md: 10, xl: 8, xs: 12 }} />

      {page?.content?.body.firstSectionRow?.copy?.title && (
        <>
          <div className="position-relative">
            <b2x.ImageFromContentV1 {...page.content.body.firstSectionRow.img} fluid />
            <div
              className={classnames(
                'content d-flex align-items-center',
                'py-4',
                { 'text-start': page.content.body.firstSectionRow.copy.alignment === 'left' },
                { 'text-center': page.content.body.firstSectionRow.copy.alignment === 'center' },
                { 'text-end': page.content.body.firstSectionRow.copy.alignment === 'right' }
              )}
            >
              <Container>
                <b2x.Row>
                  <b2x.Col className="offset-lg-1" size={{ lg: 6, xl: 5, xs: 12 }}>
                    <div>
                      <div className="fw-normal text-blue">
                        {b2x.formatHtml(page.content.body.firstSectionRow.copy.preTitle)}
                      </div>
                      <h1 className="text-blue fw-semi-bold">
                        {b2x.formatHtml(page.content.body.firstSectionRow.copy.title)}
                      </h1>
                      <h5>{b2x.formatHtml(page.content.body.firstSectionRow.copy.content)}</h5>
                    </div>
                  </b2x.Col>
                </b2x.Row>
              </Container>
            </div>
          </div>
        </>
      )}
      <Container>
        {page?.content?.body.ourServicesRow?.title && (
          <b2x.Row className="justify-content-center">
            <b2x.Col size={{ xl: 8, xs: 10 }}>
              <h2 className="text-blue text-center fw-normal my-5">
                {b2x.formatHtml(page.content.body.ourServicesRow.title)}
              </h2>
            </b2x.Col>
          </b2x.Row>
        )}
        <b2x.Row className="justify-content-center">
          {page?.content?.body.ourServicesRow?.itemList &&
            page.content.body.ourServicesRow.itemList.map((item, index) => {
              return (
                <b2x.Col
                  className="px-0 px-md-5 px-lg-3 text-center mb-5 mb-lg-0"
                  key={item.contentSectionId}
                  size={{ lg: 4, xs: 12 }}
                >
                  <b2x.ImageFromContentV1 {...item.img} className="img-fluid" fluid />
                  {item.title && <h4 className="mt-4 mb-3 my-lg-3 text-blue fw-bold">{b2x.formatHtml(item.title)}</h4>}
                  {item.content && <div className="px-3 px-lg-0 fw-normal">{b2x.formatHtml(item.content)}</div>}
                </b2x.Col>
              );
            })}
        </b2x.Row>

        {page?.content?.body.ourProductsRow?.title && (
          <b2x.Row className="justify-content-center my-3 my-lg-5">
            <b2x.Col>
              <h2 className="text-blue text-center fw-normal">
                {b2x.formatHtml(page.content.body.ourProductsRow.title)}
              </h2>
              {page.content.body.ourProductsRow.subtitle && (
                <div className="text-center h5 fw-normal">
                  {b2x.formatHtml(page.content.body.ourProductsRow.subtitle)}
                </div>
              )}
            </b2x.Col>
          </b2x.Row>
        )}

        <b2x.Row className="justify-content-center pb-5">
          {page?.content?.body.ourProductsRow?.itemList &&
            page.content.body.ourProductsRow.itemList.map((item, index) => {
              return (
                <b2x.Col className="px-3 text-center mb-3 mb-md-0" key={item.contentSectionId} size={{ md: 3, xs: 6 }}>
                  <b2x.ImageFromContentV1 {...item.img} className="mb-3 img-fluid" fluid />
                  <div className="my-3 h4 text-blue fw-bold">{b2x.formatHtml(item.title)}</div>
                </b2x.Col>
              );
            })}
        </b2x.Row>

        {page?.content?.body.sectionQuote?.title && page.content.body.sectionQuote.content && (
          <SectionQuote {...page.content.body.sectionQuote} />
        )}

        {page?.content?.body.sectionContentRowList?.map((sectionRow) => (
          <b2x.Row className="justify-content-center my-5" key={sectionRow.contentSectionId}>
            {sectionRow.contentColList?.map((listCol) => {
              return (
                <>
                  <b2x.Col
                    className={classnames(listCol.imgOrder === 'before' ? 'order-lg-1' : 'order-lg-2')}
                    size={{ lg: 6, xs: 12 }}
                  >
                    <b2x.ImageFromContentV1 className="img-fluid" {...listCol.img} fluid />
                    {listCol.note && (
                      <div className="d-block d-lg-none pt-3 pt-lg-5 small fst-italic">
                        {b2x.formatHtml(listCol.note)}
                      </div>
                    )}
                    {listCol.title && (
                      <div className="d-block d-lg-none h2 text-left text-blue mt-4 mt-lg-0">
                        {b2x.formatHtml(listCol.title)}
                      </div>
                    )}
                  </b2x.Col>
                  <b2x.Col
                    className={classnames(
                      listCol.imgOrder === 'before' ? 'order-lg-2' : 'order-lg-1',
                      'd-flex align-items-center'
                    )}
                  >
                    <div className={classnames(listCol.imgOrder === 'before' ? 'p-lg-4' : 'mx-lg-3')}>
                      <div className="d-none d-lg-block h2 text-blue">{b2x.formatHtml(listCol.title)}</div>
                      <div className="mt-3">{b2x.formatHtml(listCol.content)}</div>
                      {listCol.note && (
                        <div className="d-none d-lg-block small fst-italic mt-5">{b2x.formatHtml(listCol.note)}</div>
                      )}
                    </div>
                  </b2x.Col>
                </>
              );
            })}
          </b2x.Row>
        ))}
      </Container>
    </Page>
  );
};
