import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { HeaderBannerContentSection } from './contentTypes';

export interface MediaBannerProps extends HeaderBannerContentSection {
  className?: string;
  textColSize?: b2x.ResponsiveColSize;
  titleCss?: string;
}

export const MediaBanner = ({
  className,
  colorSubtitle,
  colorTitle,
  img,
  mobileCopyPosition,
  subtitle,
  textColSize,
  title,
  titleCss,
  video,
}: MediaBannerProps) => {
  return (
    <div className={classnames('position-relative d-flex flex-wrap justify-content-center', className)}>
      <div
        className={classnames('container-xxl d-block d-lg-none text-center my-3', {
          'order-2': mobileCopyPosition === 'bottom',
        })}
      >
        <div className="d-flex justify-content-center flex-column">
          {title && <h1 className={classnames('m-0', titleCss)}>{b2x.formatHtml(title)}</h1>}
        </div>
      </div>
      {video?.src?.xs?.video ? (
        <b2x.DeprecatedVideoFromContent autoplay {...video} />
      ) : (
        img && <b2x.ImageFromContentV1 {...img} className="w-100 order-1" fluid />
      )}
      <div className="position-absolute top-50 start-50 translate-middle w-100 d-none d-lg-block">
        <div className="container-xxl">
          <div className="d-flex justify-content-center flex-column ps-3">
            <b2x.Row>
              <b2x.Col size={textColSize}>
                {title && <h1 className={classnames(`text-${colorTitle}`)}>{b2x.formatHtml(title)}</h1>}
                {subtitle && <p className={classnames(`text-${colorSubtitle}`, 'fs-4')}>{b2x.formatHtml(subtitle)}</p>}
              </b2x.Col>
            </b2x.Row>
          </div>
        </div>
      </div>
    </div>
  );
};

MediaBanner.defaultProps = {
  textColSize: 12,
  titleCss: 'text-blue',
};
