import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { t } from './i18n/i18n';

export interface ArticleTileProps {
  category?: string;
  classname?: string;
  cta?: string;
  description?: string;
  img?: string;
  title?: string;
}
export const ArticleTile = ({ category, classname, cta, description, img, title }: ArticleTileProps) => {
  return (
    <div className={classnames('article-tile', classname)}>
      <b2x.ConditionalWrapper
        condition={cta !== undefined}
        wrapper={<b2x.router.Link style={{ display: 'block' }} to={cta} />}
      >
        <b2x.EqualHeightElement disable={['xs', 'sm']} name="article-tile-image">
          <div className="text-lg-center">
            <b2x.Image className="mb-3 rounded" fluid src={img} />
          </div>
        </b2x.EqualHeightElement>
      </b2x.ConditionalWrapper>

      <div className="content-wrapper text-lg-center">
        {category && <div className="category small text-uppercase mb-2 text-blue">{b2x.formatHtml(category)}</div>}
        {title && (
          <b2x.EqualHeightElement disable={['xs', 'sm']} name="article-tile-title">
            <h6 className="fw-bold title mb-2">{b2x.formatHtml(title)}</h6>
          </b2x.EqualHeightElement>
        )}

        {description && (
          <b2x.EqualHeightElement disable={['xs', 'sm']} name="article-tile-content">
            <div>{b2x.formatHtml(description)}</div>
          </b2x.EqualHeightElement>
        )}

        {cta && (
          <div className="py-2">
            <b2x.router.Link className="fw-bold" to={cta}>
              {t('misc.readMore')}
              <b2x.Icon className="ps-1" name="small-arrow-right" size={16} />
            </b2x.router.Link>
          </div>
        )}
      </div>
    </div>
  );
};
