import React from 'react';

import { b2x } from '.';

export interface ETrustedShopProps {
  colorScheme?: 'light' | 'dark';
  desktopDisableReviews?: 'true' | 'false';
  desktopEnableCustom?: 'true' | 'false';
  desktopEnableFadeout?: 'true' | 'false';
  desktopPosition?: 'right' | 'left';
  disableMobile?: 'true' | 'false';
  disableTrustbadge?: 'true' | 'false';
  mobileDisableReviews?: 'true' | 'false';
  mobileEnableCustom?: 'true' | 'false';
  mobileEnableFadeout?: 'true' | 'false';
  mobileEnableTopbar?: 'true' | 'false';
  mobilePosition?: 'right' | 'left';
  shopId: string;
}

export const ETrustedShop = ({
  colorScheme = 'light',
  desktopDisableReviews = 'false',
  desktopEnableCustom = 'false',
  desktopEnableFadeout = 'false',
  desktopPosition = 'right',
  disableMobile = 'false',
  disableTrustbadge = 'false',
  mobileDisableReviews = 'false',
  mobileEnableCustom = 'false',
  mobileEnableFadeout = 'true',
  mobileEnableTopbar = 'false',
  mobilePosition = 'left',
  shopId,
}: ETrustedShopProps) => {
  React.useEffect(() => {
    if (shopId) {
      b2x.addScript(
        {
          async: true,
          charset: 'UTF-8',
          dataAttributes: {
            'color-scheme': colorScheme,
            'desktop-custom-width': '156',
            'desktop-disable-reviews': desktopDisableReviews,
            'desktop-enable-custom': desktopEnableCustom,
            'desktop-enable-fadeout': desktopEnableFadeout,
            'desktop-position': desktopPosition,
            'desktop-y-offset': '0',
            'disable-mobile': disableMobile,
            'disable-trustbadge': disableTrustbadge,
            'mobile-custom-width': '156',
            'mobile-disable-reviews': mobileDisableReviews,
            'mobile-enable-custom': mobileEnableCustom,
            'mobile-enable-fadeout': mobileEnableFadeout,
            'mobile-enable-topbar': mobileEnableTopbar,
            'mobile-position': mobilePosition,
            'mobile-y-offset': '0',
          },
          id: 'eTrusted',
          src: `//widgets.trustedshops.com/js/${shopId}.js`,
          type: 'text/javascript',
        },
        { appendToBody: true }
      );
    }
  }, [
    colorScheme,
    desktopDisableReviews,
    desktopEnableCustom,
    desktopEnableFadeout,
    desktopPosition,
    disableMobile,
    disableTrustbadge,
    mobileDisableReviews,
    mobileEnableCustom,
    mobileEnableFadeout,
    mobileEnableTopbar,
    mobilePosition,
    shopId,
  ]);
  return <></>;
};
