import './MobileHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { Icon } from './Icon';
import logoSmall from './images/logo-small.svg';
// import { useMmenuOffcanvas } from './Mmenu';
import { useMobileNavigationOffcanvas } from './MobileNavigationOffcanvas';
import { useMobileSimpleSearchOffcanvas } from './MobileSimpleSearchOffcanvas';

export interface MobileHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  innerRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  visibleUntil: b2x.Breakpoint;
}

export const MobileHeader = ({ content, innerRef, recalculateHeaderHeight, visibleUntil }: MobileHeaderProps) => {
  // const [Mmenu, showMmenu] = useMmenuOffcanvas({
  //   showAccountOffcanvas: showAccountOffcanvas,
  //   showCartOffcanvas: showCartOffcanvas,
  // });

  const { session } = b2x.useAppContext();

  const [MobileNavigationOffcanvas, showMobileNavigationOffcanvas] = useMobileNavigationOffcanvas();
  const [MobileSimpleSearchOffcanvas, showMobileSimpleSearchOffcanvas] = useMobileSimpleSearchOffcanvas();

  const { showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      {/* {Mmenu} */}
      {MobileNavigationOffcanvas}
      {MobileSimpleSearchOffcanvas}
      <b2x.Sticky>
        {({ isSticky }) => (
          <div
            className={classnames(` sticky-top MobileHeader d-block d-${visibleUntil}-none border-bottom`)}
            ref={innerRef}
          >
            <Container>
              <b2x.Row className={classnames('align-items-center g-0 py-2')}>
                <b2x.Col className="d-flex">
                  <Button
                    className="me-3"
                    iconEnd={{ name: 'menu', size: 30 }}
                    onClick={showMobileNavigationOffcanvas}
                    variant="blank"
                  />
                  <b2x.router.Link to="/">
                    <b2x.Image onLoad={recalculateHeaderHeight} src={logoSmall} />
                  </b2x.router.Link>
                </b2x.Col>
                <b2x.Col className="d-flex justify-content-end">
                  <div className="hstack gap-2">
                    <Button
                      iconEnd={{ name: 'search', size: 30 }}
                      onClick={showMobileSimpleSearchOffcanvas}
                      variant="blank"
                    />
                    {b2x.appConfig.enableWishlist && session?.customer && (
                      <b2x.router.Link className="lh-1" to="/account/area/wishlist">
                        <Button className="position-relative" variant="blank">
                          <Icon name={'wishlist'} size={30} />
                          {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                            (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-secondary">
                              {(session.wishlist.products ? session.wishlist.products.length : 0) +
                                (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                            </span>
                          )}
                        </Button>
                      </b2x.router.Link>
                    )}

                    <Button className="position-relative" onClick={showCartOffcanvas} variant="blank">
                      <Icon name={'cart'} size={30} />
                      {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                        <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-secondary">
                          {session.cart.itemsNumber}
                        </span>
                      )}
                    </Button>
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </b2x.Sticky>
    </>
  );
};
