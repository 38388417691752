import { b2x } from '@b2x/react/src';
import React from 'react';

import { SliderCardASection } from '../contentTypes';
import { Icon, IconName } from '../Icon';

export interface SliderCardAProps extends SliderCardASection {}

export const SliderCardA = ({ fidelityCardList }: SliderCardAProps) => {
  return (
    <div className="slider-card-a mt-3 mb-5 my-lg-5">
      {fidelityCardList && (
        <b2x.Row className="justify-content-center">
          <b2x.Col className="pe-0 pe-xl-3" size={{ lg: 10, xl: 8, xs: 12 }}>
            <b2x.EqualHeight>
              <b2x.SwiperContext>
                <b2x.Swiper
                  breakpoints={{
                    lg: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    md: {
                      slidesPerView: 2.2,
                      spaceBetween: 48,
                    },
                    sm: {
                      slidesPerView: 1.6,
                      spaceBetween: 60,
                    },
                    xl: {
                      slidesPerView: 3,
                      spaceBetween: 48,
                    },
                    xs: {
                      slidesPerView: 1.6,
                      spaceBetween: 60,
                    },
                  }}
                >
                  {fidelityCardList.map((item, index) => (
                    <b2x.SwiperSlide key={item.contentSectionId}>
                      <div className="text-center mb-4">
                        {item.title && (
                          <b2x.EqualHeightElement name="title">
                            <h4 className="fw-bold text-blue mb-2">{b2x.formatHtml(item.title)}</h4>
                            <span className="h4 fw-normal">{b2x.formatHtml(item.subtitle)}</span>
                          </b2x.EqualHeightElement>
                        )}
                      </div>

                      {item.card?.title && (
                        <div className="rounded-3 text-center pt-5 pb-2" style={{ backgroundColor: item.card.bgColor }}>
                          <div className="display-1 fw-bold">{b2x.formatHtml(item.card.title)}</div>
                          <h5 className="fw-bold pb-4">{b2x.formatHtml(item.card.subtitle)}</h5>
                          {[...Array(index + 1)].map((e, arrayIndex) => (
                            <React.Fragment
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${item.contentSectionId}_${arrayIndex}`}
                            >
                              {item.card?.icon && (
                                <Icon className="mb-3 me-2" color="white" name={item.card.icon as IconName} size={35} />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </b2x.SwiperSlide>
                  ))}
                </b2x.Swiper>
              </b2x.SwiperContext>
            </b2x.EqualHeight>
          </b2x.Col>
        </b2x.Row>
      )}
    </div>
  );
};
