import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { GenericFormContentType } from '../contentTypes';
import { Page } from './Page';

export interface ChangePasswordPageProps {}

export const ChangePasswordPage = (props: ChangePasswordPageProps) => {
  const content = b2x.useContent<GenericFormContentType>('CHANGE_PASSWORD_FORM_CONTENT');

  return (
    <Page greyBackground>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 col-xl-5">
            <Box>
              <div className="mb-4">
                <h2 className="text-primary text-center mb-3">{b2x.formatHtml(content?.body.title)}</h2>
                <p>{b2x.formatHtml(content?.body.subTitle)} </p>
              </div>
              <b2x.ChangePasswordByTokenFormHelper className="mb-4">
                {({ fieldsHelper }) => (
                  <>
                    <b2x.FormGroup {...fieldsHelper.newPassword.formGroup} label={undefined}>
                      <b2x.PasswordInput {...fieldsHelper.newPassword.passwordInput} />
                    </b2x.FormGroup>
                    <b2x.FormGroup {...fieldsHelper.confirmNewPassword.formGroup} label={undefined}>
                      <b2x.PasswordInput {...fieldsHelper.confirmNewPassword.passwordInput} />
                    </b2x.FormGroup>
                    <div className="d-grid">
                      <b2x.Button {...fieldsHelper.buttons.submit} />
                    </div>
                  </>
                )}
              </b2x.ChangePasswordByTokenFormHelper>
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
