export const en = {
  form: {
    addToCartForm: {
      skus: {
        label: 'Size',
        placeholder: 'Select a size',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telephone' },
    },
  },
  freeSample: {
    form: {
      insertData: 'Inserisci i dati per ricevere il tuo omaggio',
      maxQuantity: 'Puoi inserire massimo {{count}} campioni in totale.',
      selectSample: 'Seleziona i {{count}} campioni',
      selected: 'Hai selezionato',
    },
    registration: {
      guestTitle: 'Sei già registrato?',
      login: 'Login',
    },
  },
  misc: {
    backToHomeBlog: 'Back to AmioAgio Magazine home',
    doubleOptionAccess: 'Log in or register now',
    moreArticles: 'More articles',
    noItemFound: 'No item found',
    storeName: 'Hartmann',
  },
  product: {
    promo: 'Promo',
  },
  welcome: 'Welcome to Hartmann',
};
