import './ArticlePage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useArticleModal } from '../ArticleModal';
import { ArticlePageContentType, MiscellaneousContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';
export interface ArticlePageProps {}

export const ArticlePage = (props: ArticlePageProps) => {
  const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  const [ArticleModal, showArticleModal] = useArticleModal({
    onClose: () => {
      b2x.cookies.setBoolean('articleModal', true, { expires: 1 });
    },
    size: 'extra-large',
  });

  const page = b2x.usePage<ArticlePageContentType>({
    populate: {
      children: true,
    },
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  const { showModal } = b2x.useModals();
  const { pageReady } = b2x.useAppContext();
  const scrollPosition = b2x.useWindowScrollPosition();

  const inizialized = React.useRef<boolean>(false);

  React.useEffect(() => {
    const modalAlreadyShown = b2x.cookies.getBoolean('articleModal');

    if (!modalAlreadyShown && pageReady && scrollPosition.percentage >= 70 && inizialized.current === false) {
      inizialized.current = true;
      b2x.wait(3000).then(() => {
        showArticleModal();
      });
    }
  }, [pageReady, scrollPosition.percentage, showArticleModal, showModal]);

  return (
    <Page className="article-page" noPaddingBottom>
      <div className="container-xxl ">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-xl-10">
            {page?.breadcrumb && (
              <div className="text-center text-lg-start">
                <b2x.router.Link
                  className="text-black extra-small text-decoration-none "
                  to={page.breadcrumb[page.breadcrumb.length - 3].fullPath}
                >
                  <b2x.Icon className="pe-1" name="small-arrow-left" size={16} />
                  {t('misc.backToHomeBlog')}
                </b2x.router.Link>
                <div className="text-center text-uppercase">
                  <b2x.router.Link
                    className="text-blue small text-decoration-none"
                    to={page.breadcrumb[page.breadcrumb.length - 2].fullPath}
                  >
                    {page.breadcrumb[page.breadcrumb.length - 2].name}
                  </b2x.router.Link>
                </div>
              </div>
            )}

            <h1 className="h2 text-center fw-bold my-3">{b2x.formatHtml(page?.content?.body.title)}</h1>
            <div className="article-content mt-3 mb-5">{b2x.formatHtml(page?.content?.body.content)}</div>
          </div>
        </div>
      </div>
      {page?.products && page.products.length > 0 && (
        <b2x.Listing
          name={`Article page - ${miscellaneousContent?.body.productPage?.relatedProducts?.title}`}
          products={page.products}
        >
          <SliderProducts
            products={page.products}
            title={miscellaneousContent?.body.productPage?.relatedProducts?.title}
          />
        </b2x.Listing>
      )}
      {ArticleModal}
    </Page>
  );
};
