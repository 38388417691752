import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { DoubleColumnContentType } from './contentTypes';
import { Section } from './Section';
import { SliderProductsLanding } from './slider/SliderProductsLanding';

interface DoubleColumnProps extends DoubleColumnContentType {}

export const DoubleColumn = ({ background, columnContent, rowCta, subtitle, title }: DoubleColumnProps) => {
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <b2x.Div background={background} marginY={3} paddingY={3}>
      <Section noMarginY>
        {(title || subtitle) && (
          <b2x.Row className="justify-content-center">
            <b2x.Col size={{ lg: 10 }}>
              <div className="text-center my-3">
                {title && <h2 className="text-blue fw-normal">{b2x.formatHtml(title)}</h2>}
                {subtitle && <h6 className="mt-3">{b2x.formatHtml(subtitle)}</h6>}
              </div>
            </b2x.Col>
          </b2x.Row>
        )}
        {columnContent && (
          <b2x.Row
            className={classnames(
              {
                'align-items-center': columnContent.productsList && columnContent.productsList.length === 0,
              },
              {
                ' mt-3': columnContent.productsList && columnContent.productsList.length > 0,
              }
            )}
          >
            <b2x.Col
              className={classnames({ 'order-lg-2': columnContent.imagePosition === 'right' })}
              size={{ lg: columnContent.imageSize === 'bigger' ? 7 : 6, xs: 12 }}
            >
              {columnContent.img && (
                <div
                  className={classnames(
                    {
                      'd-lg-block d-none': columnContent.productsList && columnContent.productsList.length === 0,
                    },
                    {
                      ' mb-4': columnContent.productsList && columnContent.productsList.length > 0,
                    }
                  )}
                >
                  {columnContent.productsList && columnContent.productsList.length > 0 ? (
                    rowCta ? (
                      <b2x.DeprecatedCta cta={rowCta}>
                        <b2x.ImageFromContentV1 {...columnContent.img} className="rounded" fluid />
                      </b2x.DeprecatedCta>
                    ) : (
                      <b2x.ImageFromContentV1 {...columnContent.img} className="rounded" fluid />
                    )
                  ) : columnContent.cta ? (
                    <b2x.DeprecatedCta cta={columnContent.cta}>
                      <b2x.ImageFromContentV1 {...columnContent.img} className="rounded" fluid />
                    </b2x.DeprecatedCta>
                  ) : (
                    <b2x.ImageFromContentV1 {...columnContent.img} className="rounded" fluid />
                  )}
                </div>
              )}
            </b2x.Col>
            <b2x.Col
              className={classnames(
                { 'order-lg-1': columnContent.imagePosition === 'right' },
                { 'px-0 px-lg-3': columnContent.productsList && columnContent.productsList.length > 0 },
                { 'px-lg-5': columnContent.productsList && columnContent.productsList.length === 0 }
              )}
              size={{ lg: columnContent.imageSize === 'bigger' ? 5 : 6, xs: 12 }}
            >
              {columnContent.productsList && columnContent.productsList.length > 0 ? (
                <b2x.ProductsByIds ids={columnContent.productsList.map(({ productId }) => productId)}>
                  {(products) =>
                    products !== undefined && (
                      <b2x.EqualHeight>
                        <b2x.Listing name="Promotion page - promo products" products={products}>
                          <SliderProductsLanding numberSlidesLg={2} products={products} />
                        </b2x.Listing>
                      </b2x.EqualHeight>
                    )
                  }
                </b2x.ProductsByIds>
              ) : (
                <div>
                  {columnContent.title && (
                    <h2
                      className={classnames('text-blue text-lg-start text-center', {
                        'fw-bold': b2x.untilBreakpoint('md', currentBreakpoint),
                      })}
                    >
                      {b2x.formatHtml(columnContent.title)}
                    </h2>
                  )}
                  {columnContent.preTitle && (
                    <p className="mt-1 text-lg-start text-center">{b2x.formatHtml(columnContent.preTitle)}</p>
                  )}
                  {columnContent.img && (
                    <div className="d-lg-none mb-3">
                      {columnContent.productsList && columnContent.productsList.length > 0 ? (
                        rowCta ? (
                          <b2x.DeprecatedCta cta={rowCta}>
                            <b2x.ImageFromContentV1 {...columnContent.img} className="rounded" fluid />
                          </b2x.DeprecatedCta>
                        ) : (
                          <b2x.ImageFromContentV1 {...columnContent.img} className="rounded" fluid />
                        )
                      ) : columnContent.cta ? (
                        <b2x.DeprecatedCta cta={columnContent.cta}>
                          <b2x.ImageFromContentV1 {...columnContent.img} className="rounded" fluid />
                        </b2x.DeprecatedCta>
                      ) : (
                        <b2x.ImageFromContentV1 {...columnContent.img} className="rounded" fluid />
                      )}
                    </div>
                  )}
                  {columnContent.description && (
                    <div className="mt-lg-4 mt-3">{b2x.formatHtml(columnContent.description)}</div>
                  )}
                  {columnContent.cta && (
                    <b2x.Div className="d-grid gap-2 d-lg-block">
                      <b2x.CtaFromContent
                        ctaProps={{ button: { className: 'mt-lg-5 mt-4 btn-block px-5' } }}
                        {...columnContent.cta}
                      />
                    </b2x.Div>
                  )}
                </div>
              )}
            </b2x.Col>
          </b2x.Row>
        )}
        {rowCta?.label && (
          <div
            className={classnames('text-center my-5', {
              'px-3': columnContent?.productsList && columnContent.productsList.length > 0,
            })}
          >
            <b2x.DeprecatedCta
              className="text-decoration-underline text-primary fw-bold text-uppercase d-block d-lg-none"
              cta={rowCta}
            >
              {b2x.formatHtml(rowCta.label)}
            </b2x.DeprecatedCta>
            <b2x.CtaFromContent {...rowCta} ctaProps={{ button: { className: 'px-5 d-none d-lg-inline-block' } }} />
          </div>
        )}
      </Section>
    </b2x.Div>
  );
};
