import { b2x } from '@b2x/react/src';

import { Banner } from '../Banner';
import { LandingTemplateDPageContentType } from '../contentTypes';
import { DoubleColumn } from '../DoubleColumn';
import { ImageSlider } from '../slider/ImageSlider';
import { Page } from './Page';

export interface LandingTemplateDPagePageProps {}

export const LandingTemplateDPage = (props: LandingTemplateDPagePageProps) => {
  const page = b2x.usePage<LandingTemplateDPageContentType>();

  const body = page?.content?.body;
  console.log(body?.banner);
  return (
    <Page noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {body?.banner && <Banner {...body.banner} />}
      {!!body?.elementRowList?.length &&
        body.elementRowList.map((row) => <DoubleColumn key={row.contentSectionId} {...row} />)}
      {body?.imgSlider && <ImageSlider {...body.imgSlider} />}
    </Page>
  );
};
