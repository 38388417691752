import './SearchPage.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Container } from '../Container';
import { SearchPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { MediaBanner } from '../MediaBanner';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { ErrorSearchPage } from './ErrorSearchPage';
import { Page } from './Page';

export interface SearchPageProps {}

export const SearchPage = (props: SearchPageProps) => {
  const { page, searchResult } = b2x.useSearch<SearchPageContentType>({
    defaultPageSize: 16,
    pageOptions: {
      populate: {
        content: true,
      },
    },
  });

  const searchPageContent = b2x.useContent<SearchPageContentType>('SEARCH_PAGE_CONTENT');

  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    searchResult: searchResult,
  });

  return (
    <>
      {searchResult?.itemsCount === 0 ? (
        <ErrorSearchPage />
      ) : (
        <Page className="search-page" noPaddingTop>
          {MobileSearchFiltersOffcanvas}
          <MediaBanner {...(page?.content?.body.header ? page.content.body.header : searchPageContent?.body.header)} />
          <Container>
            {page?.content?.body.bottomHeaderTitle && (
              <h2 className="text-center text-regular text-blue mt-2 mt-lg-3">
                {b2x.formatHtml(page.content.body.bottomHeaderTitle)}
              </h2>
            )}
            {page?.content?.body.bottomHeaderSubtitle && (
              <p className={classNames('text-center mb-4', page.content.body.bottomHeaderTitle ? 'mt-2' : 'mt-3')}>
                {b2x.formatHtml(page.content.body.bottomHeaderSubtitle)}
              </p>
            )}
          </Container>
          <Container>
            <b2x.Div className="d-none d-lg-block" marginTop={3}>
              <Breadcrumb classNameObject={{ ol: 'mb-0' }} />
            </b2x.Div>
            <b2x.Div
              className="border-bottom border-gray-300"
              display={{ lg: 'flex', xs: 'none' }}
              justifyContent={'end'}
              paddingY={2}
            >
              <SortingOptionsDropdown searchResult={searchResult} />
            </b2x.Div>
            <b2x.Div className="my-lg-3" display={{ lg: 'none', xs: 'flex' }} justifyContent="center">
              <Button
                alignItems="center"
                className="text-reset text-decoration-none btn-sm extra-small"
                iconStart={{ name: 'filter', size: 20 }}
                label={t('misc.filterAndOrderBy')}
                onClick={showMobileSearchFiltersOffcanvas}
                type="button"
                variant="link"
              />
            </b2x.Div>
            <b2x.Row>
              <b2x.Col size={{ lg: 3, xs: 12 }}>
                <b2x.SearchFormHelper searchResult={searchResult} submitOnChange>
                  {({ fieldsHelper, formik }) => <b2x.SearchFilters fieldsHelper={fieldsHelper} />}
                </b2x.SearchFormHelper>
                {page?.content?.body.description && (
                  <div className="small border-top pt-4 d-none d-lg-block">
                    <b2x.ShowMore content={page.content.body.description} truncateTo={500} />
                  </div>
                )}
              </b2x.Col>
              <b2x.Col size={{ lg: 9, xs: 12 }}>
                {searchResult && searchResult.items && (
                  <div className="pt-lg-3 pb-5">
                    <b2x.Listing name="Search page" products={searchResult.items}>
                      <b2x.ProductsTiles
                        enableExtraInfo
                        gap={{ lg: 3, xs: 2 }}
                        products={searchResult.items.map((product) => ({ product: product }))}
                        productsPerRow={{ lg: 3, md: 3, sm: 2, xl: 3, xs: 2, xxl: 3 }}
                      />
                    </b2x.Listing>
                  </div>
                )}
              </b2x.Col>
            </b2x.Row>
            <Pagination searchResult={searchResult} />
            {page?.content?.body.description && (
              <div className="small border-top pt-4 d-block d-lg-none">
                <b2x.ShowMore content={page.content.body.description} truncateTo={500} />
              </div>
            )}
          </Container>
        </Page>
      )}
    </>
  );
};

interface SortingOptionsDropdownProps {
  searchResult?: b2x.SearchProductsApiDto;
}

const SortingOptionsDropdown = ({ searchResult }: SortingOptionsDropdownProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);

  return (
    <>
      {searchResult && (
        <b2x.Dropdown
          buttonClassname="btn-sm fw-normal"
          className="order-dropdown"
          iconEnd={{ name: 'small-arrow-down', size: 12 }}
          label={t('misc.orderBy')}
          variant="blank"
        >
          {sortingOptions.map((sortingOption) => (
            <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
              <span>{sortingOption.label}</span>
            </b2x.DropdownItem>
          ))}
        </b2x.Dropdown>
      )}
    </>
  );
};

interface PaginationProps {
  searchResult?: b2x.SearchProductsApiDto;
}

const Pagination = ({ searchResult }: PaginationProps) => {
  return (
    <>
      {searchResult && (
        <b2x.Div display="flex" justifyContent="end">
          <b2x.Pagination
            currentPage={searchResult.pageNumber}
            pageOffset={2}
            showDots
            singleStepNavigation
            totalPages={searchResult.pagesCount}
          />
        </b2x.Div>
      )}
    </>
  );
};
