import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { RegistrationFormContentType } from '../contentTypes';
import { Page } from './Page';

export interface RegistrationPageProps {}

export const RegistrationPage = (props: RegistrationPageProps) => {
  const content = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');
  return (
    <Page greyBackground thingsToLoadBeforePageReady={[content]} withGenericBackgroundImage>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6 col-xxl-5 px-xl-5 px-lg-4">
            <div className="d-block d-lg-none mt-3">
              <b2x.ImageFromContentV1 {...content?.body.bannerSection?.img} className="img-fluid" fluid />
            </div>
            <div className="text-blue text-center fw-bold pt-4 pt-lg-0">
              <h2>{b2x.formatHtml(content?.body.bannerSection?.title)}</h2>
              <h4 className="py-4">{b2x.formatHtml(content?.body.bannerSection?.subTitle)}</h4>
            </div>
            <div className="d-none d-lg-block">
              <b2x.ImageFromContentV1 {...content?.body.bannerSection?.img} className="img-fluid" fluid />
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xxl-5">
            <Box>
              <div className="mb-4 ">
                <h1 className="h2 text-blue text-center mb-3">{b2x.formatHtml(content?.body.title)}</h1>
                <p className="text-center">{b2x.formatHtml(content?.body.subTitle)}</p>
              </div>
              <b2x.CustomerForm className="mb-4" />
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
