import './AccountOffCanvas.scss';

import { b2x } from '@b2x/react/src';

export interface AccountOffcanvasProps extends b2x.OffcanvasProps {}

const AccountOffcanvas = ({ ...offcanvasProps }: AccountOffcanvasProps) => {
  return (
    <b2x.Offcanvas {...offcanvasProps} placement="right">
      {({ close }) => <b2x.AccountOffcanvas close={close} />}
    </b2x.Offcanvas>
  );
};

export const useAccountOffcanvas = (props?: AccountOffcanvasProps) =>
  b2x.useStaticModal(AccountOffcanvas, 'offcanvas', props);
