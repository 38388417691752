import './ProductCategorySlider.scss';

import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { ProductCategorySection } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Section } from '../Section';
import { Slider } from './Slider';

export interface ProductCategorySliderProps extends ProductCategorySection {}

export const ProductCategorySlider = ({ content, itemList, title }: ProductCategorySliderProps) => {
  return (
    <Section className="product-category-slider" content={content} title={title}>
      {itemList && (
        <Slider
          pagination
          responsive={{
            lg: {
              slidesPerView: 3,
            },
            sm: {
              slidesPerView: 2,
            },
            xl: {
              slidesPerView: 4,
            },
          }}
          slidesPerView={1}
          spaceBetween={20}
        >
          {itemList.map((item, index) => (
            <SwiperSlide key={item.contentSectionId}>
              <div className="category-slide position-relative">
                {item.cta ? (
                  <b2x.DeprecatedCta cta={item.cta}>
                    <b2x.ImageFromContentV1 {...item.img} fluid />
                  </b2x.DeprecatedCta>
                ) : (
                  <b2x.ImageFromContentV1 {...item.img} fluid />
                )}
                {item.cta && item.cta.label && (
                  <>
                    <div className="text-center w-100 px-3 pt-4 category-link">
                      <b2x.DeprecatedCta className="text-decoration-none h5 text-blue" cta={item.cta}>
                        {b2x.formatHtml(item.cta.label)}
                      </b2x.DeprecatedCta>
                    </div>
                    <div className="text-center w-100 px-3 category-link">
                      <b2x.DeprecatedCta className="text-decoration-none small" cta={item.cta}>
                        {t('misc.viewAll')}
                        <b2x.Icon className="ps-1" name="small-arrow-right" size={16} />
                      </b2x.DeprecatedCta>
                    </div>
                  </>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Slider>
      )}
    </Section>
  );
};
