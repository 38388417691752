import './LandingTemplateCPage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { LandingTemplateCPageContentType } from '../contentTypes';
import { MediaBanner } from '../MediaBanner';
import { ProductsLanding } from '../ProductsLanding';
import { RowLanding } from '../RowLanding';
import { Page } from './Page';

export interface LandingTemplateCPageProps {}

export const LandingTemplateCPage = (props: LandingTemplateCPageProps) => {
  const page = b2x.usePage<LandingTemplateCPageContentType>();
  const body = page?.content?.body;
  const header = body?.headerBanner;

  return (
    <Page className="landing-template-c-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <MediaBanner {...header} textColSize={{ md: 10, xl: 6, xs: 12 }} />
      <Container>
        <RowLanding className="pt-0 pt-lg-5 px-2 px-lg-0" {...body?.firstRow} />
        <ProductsLanding {...body?.productsRowFirst} />
        <ProductsLanding {...body?.productsRowSecond} />
        <ProductsLanding {...body?.productsRowThird} />
        <RowLanding className="px-2 px-lg-0 pt-0 pt-lg-3 pb-5" {...body?.bottomRow} />
      </Container>
    </Page>
  );
};
