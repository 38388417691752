import {
  // eslint-disable-next-line no-restricted-imports
  CreateSmartOrderRequestApiDto,
  CustomerRequestApiDto,
} from '@b2x/storefront-api-js-client/src';
import classNames from 'classnames';
import React from 'react';
import * as yup from 'yup';

import { useOrdersApi } from '../api/useOrdersApi';
import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { appConfig } from '../config';
import { ConsentsContentType } from '../contentTypes';
import { Select } from '../form/fields/Select';
import { t } from '../i18n/i18n';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { usePaymentHandler } from '../usePaymentHandler';
import { formatHtml } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { DynamicField } from './fields/DynamicField';
import { PasswordInput, PasswordInputProps, TextInput, TextInputProps } from './fields/Input';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  FormFieldsChangeHandlerProps,
  formikBoolean,
  FormikHelpers,
  formikString,
  getFormikBooleanValue,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { SmartOrderFormA } from './SmartOrderFormA';
import {
  AddressFieldsHelper,
  AddressFormValues,
  AddressValidationSchema,
  AddressValidationSchemaSelector,
  getAddressFromFormValues,
  getAddressValidationSchema,
  useAddressForm,
} from './useAddressForm';

interface FormValues {
  address: AddressFormValues;
  email: formikString;
  marketingConsent: formikBoolean;
  name: formikString;
  newsletterConsent: formikBoolean;
  password: formikString;
  profilingConsent: formikBoolean;
  surname: formikString;
}

interface FieldsHelper {
  address: AddressFieldsHelper;
  buttons: {
    submit: FormButtonProps;
  };
  email: { formGroup: FormGroupProps; textInput: TextInputProps };
  marketingConsent?: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  name: { formGroup: FormGroupProps; textInput: TextInputProps };
  newsletterConsent?: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  password?: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  profilingConsent?: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  surname: { formGroup: FormGroupProps; textInput: TextInputProps };
}

interface ValidationSchemaSelector {
  address: AddressValidationSchemaSelector;
  email: boolean;
  marketingConsent: boolean;
  name: boolean;
  newsletterConsent: boolean;
  password: boolean;
  profilingConsent: boolean;
  surname: boolean;
}

export type SmartOrderSkuContentType = {
  id: string;
  price: number;
  quantity: number;
};

export interface SmartOrderFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  couponCode?: string;
  maxQuantity: number;
  skus?: Array<{
    id: string;
    price: number;
    quantity: number;
  }>;
}

type ValidationSchema = {
  address: yup.ObjectSchema<AddressValidationSchema>;
  email: yup.StringSchema;
  marketingConsent: yup.BooleanSchema;
  name: yup.StringSchema;
  newsletterConsent: yup.BooleanSchema;
  password: yup.StringSchema;
  profilingConsent: yup.BooleanSchema;
  surname: yup.StringSchema;
};

const defaultValidationSchemaSelector: ValidationSchemaSelector = {
  address: {
    addressLine2: true,
    addressLine3: true,
    alternativePhone: true,
    latitude: true,
    longitude: true,
    middleName: true,
    phone: true,
    region: true,
  },
  email: true,
  marketingConsent: true,
  name: true,
  newsletterConsent: true,
  password: true,
  profilingConsent: true,
  surname: true,
};

export const SmartOrderFormHelper = ({
  children,
  className,
  couponCode,
  initialValues,
  maxQuantity,
  skus,
  validationSchemaSelector = defaultValidationSchemaSelector,
}: SmartOrderFormProps) => {
  const { smartOrder } = useOrdersApi();
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');
  const { getAddressFieldsHelper, getAddressInitialValues } = useAddressForm(
    'CustomerAddressFormDuringCheckout',
    undefined
  );

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();
  const { PaymentModal, handleSmartPaymentCallback } = usePaymentHandler();
  const { session } = useAppContext();
  const isLogged = session?.userLogged;

  const _initialValues = React.useMemo(() => {
    return {
      address: {
        ...getAddressInitialValues(),
        name: session?.customer?.name || '',
        surname: session?.customer?.surname || '',
      },
      email: session?.customer?.email || '',
      marketingConsent: false,
      name: session?.customer?.name || '',
      newsletterConsent: false,
      password: '',
      profilingConsent: false,
      surname: session?.customer?.surname || '',
      ...initialValues,
    };
  }, [
    initialValues,
    getAddressInitialValues,
    session?.customer?.email,
    session?.customer?.name,
    session?.customer?.surname,
  ]);

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      address: yup.object(getAddressValidationSchema(validationSchemaSelector.address)),
      email: isLogged ? yup.string().email() : yup.string().required().email(),
      marketingConsent: yup.boolean(),
      name: yup.string().required(),
      newsletterConsent: yup.boolean(),
      password: isLogged ? yup.string() : yup.string().required(),
      profilingConsent: yup.boolean(),
      surname: yup.string().required(),
    }),
    [validationSchemaSelector.address, isLogged]
  );

  const handleSubmit = React.useCallback(
    async ({ address, password, ...others }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const customer: CustomerRequestApiDto = {
        email: others.email,
        id: session?.customer?.id || '0',
        marketingConsent: getFormikBooleanValue(others.marketingConsent),
        name: others.name,
        newsletterConsent: getFormikBooleanValue(others.newsletterConsent),
        profilingConsent: getFormikBooleanValue(others.profilingConsent),
        surname: others.surname,
      };

      const dataToSend: CreateSmartOrderRequestApiDto = {
        couponCode,
        customer: !isLogged ? customer : undefined,
        password,
        shippingAddress: getAddressFromFormValues(address),
        skus: skus?.filter((sku) => sku.quantity > 0),
      };

      smartOrder(dataToSend).then((response) => handleSmartPaymentCallback(response, dataToSend));
    },
    [couponCode, skus, isLogged, smartOrder, session, handleSmartPaymentCallback]
  );

  const handleNameChange = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      formikHelpers.setFieldValue('address.name', values.name),
    []
  );

  const handleSurnameChange = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      formikHelpers.setFieldValue('address.surname', values.surname),
    []
  );

  const handleFieldsChange = React.useMemo<FormFieldsChangeHandlerProps<FormValues>>(
    () => ({
      name: handleNameChange,
      surname: handleSurnameChange,
    }),
    [handleNameChange, handleSurnameChange]
  );

  const skusQuantity = React.useMemo(() => {
    return skus?.reduce((acc, sku) => acc + sku.quantity, 0) || 0;
  }, [skus]);

  return (
    <HelpedForm<FormValues>
      className={classNames('SmartOrderForm', className)}
      enableReinitialize
      initialValues={_initialValues}
      onFieldsChange={handleFieldsChange}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          address: getAddressFieldsHelper(formik),
          buttons: {
            submit: {
              disabled: isSubmitButtonDisabled(formik) || skusQuantity > maxQuantity || skusQuantity === 0,
              label: isLogged ? "Ricevi l'omaggio" : "Registrati e ricevi l'omaggio",
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          email: {
            formGroup: { label: t('form.customerForm.email.label'), names: ['email'] },
            textInput: {
              disabled: isLogged,
              name: 'email',
              placeholder: t('form.customerForm.email.placeholder'),
              type: 'email',
            },
          },
          marketingConsent:
            !isLogged && consentsContent?.body.marketing?.enabled
              ? {
                  checkbox: {
                    id: 'marketingConsent',
                    label: formatHtml(consentsContent.body.marketing.label),
                    name: 'marketingConsent',
                  },
                  formGroup: { names: ['marketingConsent'] },
                }
              : undefined,
          name: {
            formGroup: { label: t('form.customerForm.name.label'), names: ['name'] },
            textInput: { name: 'name', placeholder: t('form.customerForm.name.placeholder') },
          },
          newsletterConsent:
            !isLogged && consentsContent?.body.newsletter?.enabled
              ? {
                  checkbox: {
                    id: 'newsletterConsent',
                    label: formatHtml(consentsContent.body.newsletter.label),
                    name: 'newsletterConsent',
                  },
                  formGroup: { names: ['newsletterConsent'] },
                }
              : undefined,
          password: isLogged
            ? undefined
            : {
                formGroup: { label: t('form.customerForm.password.label'), names: ['password'] },
                passwordInput: {
                  autoComplete: 'new-password',
                  name: 'password',
                  placeholder: t('form.customerForm.password.placeholder'),
                },
              },
          profilingConsent:
            !isLogged && consentsContent?.body.profiling?.enabled
              ? {
                  checkbox: {
                    id: 'profilingConsent',
                    label: formatHtml(consentsContent.body.profiling.label),
                    name: 'profilingConsent',
                  },
                  formGroup: { names: ['profilingConsent'] },
                }
              : undefined,
          surname: {
            formGroup: { label: t('form.customerForm.surname.label'), names: ['surname'] },
            textInput: { name: 'surname', placeholder: t('form.customerForm.surname.placeholder') },
          },
        };

        return (
          <>
            {children ? (
              children({
                closeModal,
                fieldsHelper,
                formik,
                insideModal,
              })
            ) : (
              <>
                <FormGroup {...fieldsHelper.name.formGroup}>
                  <TextInput {...fieldsHelper.name.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.surname.formGroup}>
                  <TextInput {...fieldsHelper.surname.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.email.formGroup}>
                  <TextInput {...fieldsHelper.email.textInput} disabled={fieldsHelper.email.textInput.disabled} />
                </FormGroup>
                {fieldsHelper.password && (
                  <FormGroup {...fieldsHelper.password.formGroup}>
                    <PasswordInput {...fieldsHelper.password.passwordInput} />
                  </FormGroup>
                )}
                <FormGroup {...fieldsHelper.address.addressLine1.formGroup}>
                  <TextInput {...fieldsHelper.address.addressLine1.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.address.civicNumber.formGroup}>
                  <TextInput {...fieldsHelper.address.civicNumber.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.address.addressLine2.formGroup}>
                  <TextInput {...fieldsHelper.address.addressLine2.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.address.city.formGroup}>
                  <TextInput {...fieldsHelper.address.city.textInput} />
                </FormGroup>
                {fieldsHelper.address.province && (
                  <FormGroup {...fieldsHelper.address.province.formGroup}>
                    <Select {...fieldsHelper.address.province.select} />
                  </FormGroup>
                )}
                <FormGroup {...fieldsHelper.address.zipCode.formGroup}>
                  <DynamicField {...fieldsHelper.address.zipCode.dynamicField} />
                </FormGroup>
                <FormGroup {...fieldsHelper.address.country.formGroup}>
                  <Select {...fieldsHelper.address.country.select} />
                </FormGroup>
                <FormGroup {...fieldsHelper.address.phone.formGroup}>
                  <TextInput {...fieldsHelper.address.phone.textInput} />
                </FormGroup>
                {fieldsHelper.marketingConsent && (
                  <FormGroup {...fieldsHelper.marketingConsent.formGroup}>
                    <Checkbox {...fieldsHelper.marketingConsent.checkbox} />
                  </FormGroup>
                )}
                {fieldsHelper.profilingConsent && (
                  <FormGroup {...fieldsHelper.profilingConsent.formGroup}>
                    <Checkbox {...fieldsHelper.profilingConsent.checkbox} />
                  </FormGroup>
                )}
                {fieldsHelper.newsletterConsent && (
                  <FormGroup {...fieldsHelper.newsletterConsent.formGroup}>
                    <Checkbox {...fieldsHelper.newsletterConsent.checkbox} />
                  </FormGroup>
                )}
                <Button {...fieldsHelper.buttons.submit} />
              </>
            )}
            {PaymentModal}
          </>
        );
      }}
    </HelpedForm>
  );
};

export type SmartOrderFormVariants = 'A';

const SmartOrderFormController = (props: PropsWithCustomComponentWithoutChildren<SmartOrderFormProps>) => (
  <VariantsController<SmartOrderFormProps, SmartOrderFormVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: SmartOrderFormA },
      defaultComponent: SmartOrderFormHelper,
      name: 'SmartOrderForm',
    }}
  />
);

export { SmartOrderFormController as SmartOrderForm };
