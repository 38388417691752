import './Banner.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { BannerContentType } from './contentTypes';

interface BannerProps extends BannerContentType {}

export const Banner = ({ copy, img, video }: BannerProps) => {
  return (
    <div className="banner-component">
      <div className="background position-relative h-100 text-center">
        {video?.src && video.src.length > 1 ? (
          <b2x.DeprecatedVideoAsBackgroundFromContent {...video} />
        ) : (
          <b2x.ImageFromContentV1 {...img} fluid />
        )}
        {copy?.cta && <b2x.DeprecatedCta className="stretched-link" cta={copy.cta} />}
      </div>
      <div
        className={classNames(
          'message d-md-flex h-100 align-items-center',
          // padding
          'px-3 py-4',
          // alignment
          { 'text-start': copy?.alignment === 'left' },
          { 'text-center': copy?.alignment === 'center' },
          { 'text-end': copy?.alignment === 'right' }
        )}
      >
        <div className="container-xxl">
          <div className="col-lg-5 col-md-6 ps-md-4 ps-lg-4 ps-xl-3">
            <div className={classNames(`text-${copy?.color}`)}>
              {copy?.title && <h3 className="h1 fw-bold title mb-3">{b2x.formatHtml(copy.title)}</h3>}
              {copy?.content && <div className="description h4 fw-normal mb-3">{b2x.formatHtml(copy.content)}</div>}
              {copy?.disclaimer && (
                <div className="extra-small disclaimer mb-3"> {b2x.formatHtml(copy.disclaimer)}</div>
              )}
              {copy?.cta && copy.cta.label && (
                <div>
                  <b2x.DeprecatedCta
                    className={classNames('btn', `btn-${copy.cta.variant}`, 'd-none d-md-inline-block ')}
                    cta={copy.cta}
                  >
                    {b2x.formatHtml(copy.cta.label)}
                  </b2x.DeprecatedCta>
                  <b2x.DeprecatedCta className={classNames('btn btn-primary d-md-none')} cta={copy.cta}>
                    {b2x.formatHtml(copy.cta.label)}
                  </b2x.DeprecatedCta>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
